/* eslint-disable no-undef */
import { action, observable, runInAction } from 'mobx';

import { clearToken, setToken } from 'api';
import { fetchProfile } from 'api/profile';

import { clearStore as clearUsers } from './users';
import { clearStore as clearPrompts } from './prompts';
import { clearStore as clearBots } from './bots';
import { clearStore as clearCubit } from './cubit';

export interface IProfile {
  id: string;
  email: string;
  full_name?: string;
  company?: string;
  admin: boolean;
  moderator: boolean;
  public_token: boolean;
  subscriptions?: string[];
  user_bots?: string[];
  last_login?: string;
  agreement_accepted?: string;
  time_password_changed?: string;
  created_at: string;
}

interface IProfileStore {
  isAuthorized: boolean;
  data?: IProfile | null;
}

const defState: IProfileStore = {
  isAuthorized: false,
  data: null,
};

const profile = observable<IProfileStore>(defState);

export const clearStore = action(() => Object.assign(profile, defState));

const AUTH_TOKEN = 'AUTH_TOKEN';

export const restoreToken = () => {
  const token = localStorage.getItem(AUTH_TOKEN);
  token && setToken(token);
  return !!token;
};

export const updateToken = (token: string) => {
  localStorage.setItem(AUTH_TOKEN, token);
  setToken(token);
};

export const deleteToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  clearToken();
};

export const setAuthorized = async (value: boolean) => {
  runInAction(() => {
    profile.isAuthorized = value;
    if (!value) {
      profile.data = null;
      clearUsers();
      clearPrompts();
      clearBots();
      clearCubit();
    }
  });
};

export const loadProfile = async (hideError?: boolean) => {
  const data = await fetchProfile(hideError);
  runInAction(() => (profile.data = data));
  return !!data;
};

export const setProfile = (data: IProfile) =>
  runInAction(
    () =>
      (profile.data = {
        ...data,
        last_login: profile.data?.last_login || data.last_login,
      })
  );

export default profile;
