/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Checkbox, Modal, Space } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { IUserSubscription, subscribe } from 'store/users';

export type TSubscriptionsRef = { open: () => void };
export type TSubscriptionsProps = {
  bots: DefaultOptionType[];
  userId?: string;
  subscriptions?: IUserSubscription[];
};

const Subscriptions = forwardRef<TSubscriptionsRef, TSubscriptionsProps>((props, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [values, setValues] = useState<string[]>([]);

  const toggleValue = (value: string) =>
    setValues(vals =>
      vals.includes(value)
        ? vals.filter(item => item !== value)
        : [...vals, value]
    );

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setValues((props.subscriptions || []).map(({ id }) => id));
        setOpened(true);
      },
    }),
    [props.subscriptions]
  );

  const handleClose = () => setOpened(false);

  const [saving, setSaving] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!props.userId) return;
    try {
      setSaving(true);
      const currentSubscriptions = props.subscriptions || [];
      const newSubscriptions = values
        .filter(item => !currentSubscriptions.find(({ id }) => id === item))
        .map<IUserSubscription>(id => ({ id }));
      const oldSubscriptions = currentSubscriptions.filter(({ id }) =>
        values.includes(id)
      );
      const data = await subscribe(props.userId, [
        ...oldSubscriptions,
        ...newSubscriptions,
      ]);
      data && handleClose();
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      title="Select user bots"
      okText="Save"
      cancelText="Close"
      open={opened}
      confirmLoading={saving}
      onOk={handleSubmit}
      onCancel={handleClose}
    >
      <Space direction="vertical" size="small">
        {props.bots.map(({ value, label }) => (
          <Checkbox
            key={`users-bots-checkbox-${value}`}
            checked={values.includes(value as string)}
            disabled={saving}
            onChange={() => toggleValue(value as string)}
          >
            {label}
          </Checkbox>
        ))}
      </Space>
    </Modal>
  );
});

export default Subscriptions;
