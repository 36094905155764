import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import Markdown from 'components/markdown';

import getData from './data';

import styles from './styles.module.scss';

const terms: { open?: (individual?: boolean) => void } = {};

export const useTerms = () => terms;

const Terms: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const [isIndividual, setIndividual] = useState<boolean>(false);

  useEffect(() => {
    terms.open = individual => {
      setIndividual(!!individual);
      setOpened(true);
    };
    return () => (terms.open = undefined);
  }, []);

  const handleClose = () => setOpened(false);

  return (
    <Modal
      title="Terms of Use"
      cancelText="Close"
      open={opened}
      width={800}
      onCancel={handleClose}
      closable={false}
      maskClosable={false}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      <Markdown className={styles.markdown}>
        {getData(location.hostname, isIndividual)}
      </Markdown>
    </Modal>
  );
};

export default Terms;
