import { useMemo } from 'react';

const def = {
  user: 'User',
  users: 'Users',
};

const labels: Record<string, typeof def> = {
  prescreen: {
    user: 'Candidate',
    users: 'Candidates',
  },
  sifu: {
    user: 'Client',
    users: 'Clients',
  },
};

const relations: Record<string, keyof typeof labels> = {
  localhost: 'prescreen',
  prescreenai: 'prescreen',
  sifu: 'sifu',
};

export const getLabels = (host: string) => {
  const key = Object.entries(relations).find(([key]) =>
    host.toLowerCase().includes(key.toLowerCase())
  )?.[1];
  return key ? labels[key] : def;
};

const useLabels = () => useMemo(() => getLabels(location.href), []);

export default useLabels;
