import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Collapse,
  Empty,
  Flex,
  List,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import cn from 'classnames';

import { download } from 'api';
import { frontDate } from 'utils/format';
import { fetchUserHistory } from 'api/users';
import {
  IUser,
  IUserSession,
  IUserMessage,
  IUserSessionUsageItem,
} from 'store/users';

import Evaluation, { TEvaluationRef } from '../../evaluation';
import Usages, { TUsagesRef } from '../../usages';
import Profile, { TProfileRef } from '../../profile';

import styles from '../../../styles.module.scss';

export type TChatProps = {
  user: IUser;
  botId: string;
  isAdmin: boolean;
  isHRBot: boolean;
};

const Chat: React.FC<TChatProps> = ({ user, botId, isAdmin, isHRBot }) => {
  const { xs } = useBreakpoint();

  const evaluationRef = useRef<TEvaluationRef>(null);
  const usagesRef = useRef<TUsagesRef>(null);
  const profileRef = useRef<TProfileRef>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<IUserSession[]>([]);
  const [chatId, setChatId] = useState<string | null>();

  useEffect(() => {
    if (!botId || !user?.subscriptions?.find(({ id }) => id === botId)) {
      setLoading(false);
      setHistory([]);
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const historyData = await fetchUserHistory(user.id, botId);
        setHistory(historyData || []);
      } finally {
        setLoading(false);
      }
    })();
  }, [user, botId]);

  const userChatsOptions: DefaultOptionType[] = useMemo(
    () =>
      history
        .filter(({ messages }) => !!messages?.length)
        .map(({ id, last_interaction, red_flags }) => ({
          value: id,
          label: (
            <>
              <span className={styles.weakTitle}>Started at</span>{' '}
              {frontDate(last_interaction)}
              {!!red_flags?.length && <Badge dot />}
            </>
          ),
        }))
        .reverse(),
    [history]
  );

  useEffect(
    () =>
      void (
        user &&
        botId &&
        userChatsOptions.length >= 1 &&
        setChatId(userChatsOptions[0].value as string)
      ),
    [user, userChatsOptions]
  );

  const chat = history.find(({ id }) => chatId === id);
  const redFlags = chat?.red_flags || [];
  const usages = chat?.usage || {};
  const sessions = useMemo(() => {
    const result: Record<string, IUserMessage[]> = {};
    const messages = chat?.messages || [];
    messages.forEach(message => {
      result[message.session_id] = result[message.session_id] || [];
      result[message.session_id].push(message);
    });
    return Object.values(result);
  }, [history, chatId]);

  const handleProfileClick = () => chatId && profileRef.current?.open(chatId);

  const handleSummaryClick = () =>
    chatId && evaluationRef.current?.open(chatId, user);

  const handleUsagesClick = () => usages && usagesRef.current?.open(usages);

  const handleDownloadHistory = () =>
    download(
      ['admin', 'download_chat_history'],
      {
        extension: 'pdf',
        chat_id: chatId || '',
        timezone_offset: new Date().getTimezoneOffset() * -1,
      },
      `${user.email}_history`
    );

  const isChatInactive = chat?.status === 'inactive';
  const isUsagesEmpty = !Object.values(usages).some(
    (items: IUserSessionUsageItem[]) =>
      items.some(({ usage }) => Object.values(usage).some(value => value > 0))
  );
  const isMessagesEmpty = !sessions?.length;
  const isBotDataEmpty = isMessagesEmpty && !userChatsOptions.length;

  if (loading) {
    return <Spin className={styles.loading} />;
  }

  if (isBotDataEmpty) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        {userChatsOptions.length > 1 && (
          <Col span={24}>
            <Select
              className={styles.bots}
              value={chatId}
              options={userChatsOptions}
              placeholder="Select chat"
              onChange={setChatId}
            />
          </Col>
        )}
        <Col span={24}>
          <Flex
            vertical={xs}
            justify="space-between"
            align="center"
            wrap="wrap"
            gap={10}
          >
            <Space.Compact block={xs}>
              {redFlags?.map(flag => (
                <Tag
                  key={`chat-${chatId}-flag-${flag}`}
                  bordered={false}
                  color="volcano"
                >
                  {flag}
                </Tag>
              ))}
            </Space.Compact>
            {!xs && <div />}
            <Space.Compact
              direction={xs ? 'vertical' : 'horizontal'}
              block={xs}
            >
              <Button
                disabled={isMessagesEmpty}
                onClick={handleDownloadHistory}
              >
                Download messages
              </Button>
              <Button onClick={handleProfileClick}>Show Profile</Button>
              {isHRBot && (
                <Button onClick={handleSummaryClick}>Get Summary</Button>
              )}
              {isAdmin && (
                <Button disabled={isUsagesEmpty} onClick={handleUsagesClick}>
                  Show Usages
                </Button>
              )}
            </Space.Compact>
          </Flex>
        </Col>
        {!isMessagesEmpty && (
          <Col span={24}>
            <Collapse
              accordion
              items={sessions.map(messages => ({
                key: messages[0].session_id,
                headerClass: styles.messagesHeader,
                label: (
                  <>
                    <span className={styles.weakTitle}>Last message at</span>{' '}
                    {frontDate(messages.at(-1)?.datetime)}
                  </>
                ),
                children: (
                  <Row gutter={[10, 10]}>
                    {messages.map((message, messageIndex) => (
                      <Col
                        span={18}
                        offset={message.role === 'assistant' ? 0 : 6}
                        key={`chat-${chatId}-session-${message.session_id}-message-${message.id}-${messageIndex}`}
                      >
                        <List
                          size="small"
                          dataSource={message.content}
                          renderItem={line => <List.Item>{line}</List.Item>}
                          className={cn(styles.messages, styles[message.role])}
                          footer={frontDate(message.datetime)}
                        />
                      </Col>
                    ))}
                  </Row>
                ),
              }))}
            />
          </Col>
        )}
      </Row>
      {isHRBot && <Evaluation ref={evaluationRef} />}
      <Profile ref={profileRef} />
      <Usages ref={usagesRef} />
    </>
  );
};

export default Chat;
