/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import React, {
  FormEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Collapse,
  Descriptions,
  Divider,
  Drawer,
  Empty,
  Flex,
  Input,
  List,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import cn from 'classnames';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { ColumnsType } from 'antd/es/table';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { frontDate } from 'utils/format';
import { fetchUserProfile, fetchUserHistory } from 'api/users';
import profile from 'store/profile';
import bots, { IBot, loadBots } from 'store/bots';
import users, {
  loadUsers,
  removeUser,
  IUser,
  IUserSession,
  loadFlags,
} from 'store/users';

// import Invitation, { TInvitationRef } from './components/invitation';
// import Bots, { TBotsRef } from './components/bots';
// import Password, { TPasswordRef } from './components/password';

import styles from './styles.module.scss';

type TStatus = 'user' | 'moderator' | 'admin';

const getColumns = (
  onCreate: undefined | (() => void),
  onOpen: (id: string) => void,
  onDelete: (id: string) => void,
  removing: string[]
): ColumnsType<IBot> => [
  {
    title: 'Name',
    dataIndex: 'name',
    ellipsis: true,
  },
  // {
  //   title: 'Admin',
  //   dataIndex: 'admin',
  //   width: 100,
  //   align: 'center',
  //   responsive: ['sm'],
  //   render: value => <Checkbox checked={value} />,
  // },
  // {
  //   title: 'Moderator',
  //   dataIndex: 'moderator',
  //   width: 100,
  //   align: 'center',
  //   responsive: ['sm'],
  //   render: value => <Checkbox checked={value} />,
  // },
  {
    title: () => (
      <div className={styles.create}>
        <Button size="middle" onClick={onCreate} disabled>
          Create
        </Button>
      </div>
    ),
    width: 160,
    render: ({ id }) => (
      <div className={styles.actions}>
        <Button
          onClick={() => onOpen(id)}
          size="small"
          disabled={removing.includes(id)}
        >
          Open
        </Button>
        <Popconfirm
          title="Delete the user"
          description="Are you sure to delete this user?"
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          okText="Yes"
          cancelText="No"
          onConfirm={() => {
            onDelete(id);
          }}
        >
          <Button danger size="small" loading={removing.includes(id)} disabled>
            Delete
          </Button>
        </Popconfirm>
      </div>
    ),
  },
];

const Bots: React.FC = () => {
  const { inited, list } = bots;
  const { xs, sm } = useBreakpoint();

  const [loading, setLoading] = useState<boolean>(false);
  const init = async () => {
    try {
      setLoading(true);
      await loadBots();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !inited && init();
  }, [inited]);

  const [openedId, setOpenedId] = useState<string | null>(null);
  const bot = list.find(({ id }) => id === openedId);

  const handleClose = () => setOpenedId(null);

  const [removing, setRemoving] = useState<string[]>([]);
  const handleDelete = async (id: string) => {
    try {
      setRemoving(ids => [...ids, id]);
      await removeUser(id);
    } finally {
      setRemoving(ids => ids.filter(item => item !== id));
    }
  };

  const preparedBots = [...list].sort((a, b) =>
    a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0
  );

  const [status, setStatus] = useState<TStatus>();

  const isAdmin = !!profile.data?.admin;

  const profileBots: DefaultOptionType[] = isAdmin
    ? bots.list.map(({ id, name }) => ({
        value: id,
        label: name,
      }))
    : profile.data?.user_bots
    ? bots.list
        .filter(({ id }) => profile.data?.user_bots?.includes(id))
        .map(({ id, name }) => ({ value: id, label: name }))
    : [];

  return (
    <div className={styles.root}>
      <Table
        rowKey="id"
        loading={loading}
        tableLayout={xs ? 'auto' : 'fixed'}
        columns={getColumns(() => null, setOpenedId, handleDelete, removing)}
        dataSource={preparedBots}
        pagination={false}
        size="small"
        sticky={{ offsetHeader: 38 }}
      />
      <Drawer
        className={styles.drawer}
        title={bot?.name}
        width={1000}
        onClose={handleClose}
        open={!!openedId}
        extra={
          <Button icon={!sm && <CloseOutlined />} onClick={handleClose}>
            {sm && 'Close'}
          </Button>
        }
      >
        {/*{isAdmin && (*/}
        {/*  <Space.Compact block className={styles.status}>*/}
        {/*    <Input value="Status" disabled />*/}
        {/*    <Select*/}
        {/*      value={status}*/}
        {/*      options={[*/}
        {/*        { value: 'user', label: 'User' },*/}
        {/*        { value: 'moderator', label: 'Moderator' },*/}
        {/*        { value: 'admin', label: 'Admin' },*/}
        {/*      ]}*/}
        {/*      disabled*/}
        {/*      onChange={setStatus}*/}
        {/*    />*/}
        {/*    <Button type="primary" disabled>*/}
        {/*      Save*/}
        {/*    </Button>*/}
        {/*  </Space.Compact>*/}
        {/*)}*/}
        {/*<Divider />*/}
        {/*<Row gutter={[0, 10]}>*/}
        {/*  <Col span={24}>*/}
        {/*    <Space.Compact block>*/}
        {/*      <Select*/}
        {/*        className={styles.bots}*/}
        {/*        value={botId}*/}
        {/*        options={userBots}*/}
        {/*        disabled={!userBots.length}*/}
        {/*        placeholder="Select bot"*/}
        {/*        onChange={setBotId}*/}
        {/*      />*/}
        {/*      <Button onClick={() => null}>Change</Button>*/}
        {/*    </Space.Compact>*/}
        {/*  </Col>*/}
        {/*  <Col span={24}>*/}
        {/*    {botDataLoading ? (*/}
        {/*      <Spin className={styles.loading} />*/}
        {/*    ) : isBotDataEmpty ? (*/}
        {/*      botId && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />*/}
        {/*    ) : (*/}
        {/*      <>*/}
        {/*        {!isInfoEmpty && (*/}
        {/*          <Descriptions size="small" column={1} items={info} />*/}
        {/*        )}*/}
        {/*        {!isHistoryEmpty && (*/}
        {/*          <Collapse*/}
        {/*            accordion*/}
        {/*            items={history.map(*/}
        {/*              ({ id, last_interaction, messages, red_flags }) => ({*/}
        {/*                key: id,*/}
        {/*                label: (*/}
        {/*                  <Flex justify="space-between" wrap="wrap" gap={10}>*/}
        {/*                    {frontDate(last_interaction)}*/}
        {/*                    <Space.Compact>*/}
        {/*                      {red_flags?.map(flag => (*/}
        {/*                        <Tag bordered={false} color="volcano">*/}
        {/*                          {flag}*/}
        {/*                        </Tag>*/}
        {/*                      ))}*/}
        {/*                    </Space.Compact>*/}
        {/*                  </Flex>*/}
        {/*                ),*/}
        {/*                children: (*/}
        {/*                  <Row gutter={[10, 10]}>*/}
        {/*                    {messages.map(message => (*/}
        {/*                      <Col*/}
        {/*                        span={18}*/}
        {/*                        offset={message.role === 'assistant' ? 0 : 6}*/}
        {/*                        key={`session-${id}-message-${message.id}-${message.session_id}`}*/}
        {/*                      >*/}
        {/*                        <List*/}
        {/*                          size="small"*/}
        {/*                          dataSource={message.content}*/}
        {/*                          renderItem={line => (*/}
        {/*                            <List.Item>{line}</List.Item>*/}
        {/*                          )}*/}
        {/*                          className={cn(*/}
        {/*                            styles.messages,*/}
        {/*                            styles[message.role]*/}
        {/*                          )}*/}
        {/*                          footer={frontDate(message.datetime)}*/}
        {/*                        />*/}
        {/*                      </Col>*/}
        {/*                    ))}*/}
        {/*                  </Row>*/}
        {/*                ),*/}
        {/*              })*/}
        {/*            )}*/}
        {/*          />*/}
        {/*        )}*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Drawer>
      {/*<Invitation ref={invitationRef} bots={profileBots} />*/}
      {/*<Password ref={passwordRef} userId={user?.id} />*/}
      {/*<Bots*/}
      {/*  ref={botsRef}*/}
      {/*  bots={profileBots}*/}
      {/*  userId={user?.id}*/}
      {/*  subscriptions={user?.subscriptions}*/}
      {/*/>*/}
    </div>
  );
};

export default observer(Bots);
