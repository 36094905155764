import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Col, Divider, Flex, Modal, Tag, Typography } from 'antd';

import { IUserSessionUsage, IUserSessionUsageItem } from 'store/users';

import styles from '../styles.module.scss';

export type TUsagesRef = {
  open: (usages: IUserSessionUsage) => void;
};

const Usage: React.FC<{ title: string; data?: IUserSessionUsageItem[] }> = ({
  title,
  data,
}) => {
  if (!data) return null;
  if (
    !data.some(({ usage }) =>
      typeof usage === 'object'
        ? Object.values(usage).some(value => !!value)
        : usage
    )
  )
    return null;
  const round = (value: number) => {
    let digits = 2;
    if (0 < value && value < 1) {
      digits = value.toString().match(/[1-9]/)?.index || 2;
    }
    return value.toFixed(digits);
  };
  return (
    <Col span={22} offset={1}>
      <Flex align="center" gap={10} className={styles.sectionTitle}>
        <Typography.Title level={5}>{title}</Typography.Title>
        <Divider />
      </Flex>
      <Col span={22} offset={1}>
        <Flex align="flex-start" gap={4} vertical>
          {data.map(item => {
            const units = item.units || 'USD';
            const scores = [];
            if (typeof item.usage === 'object') {
              if (item.usage.input) {
                scores.push(`input: ${round(item.usage.input)} ${units}`);
              }
              if (item.usage.output) {
                scores.push(`output: ${round(item.usage.output)} ${units}`);
              }
              if (item.usage.total) {
                scores.push(`total: ${round(item.usage.total)} ${units}`);
              }
            }
            if (!scores.length) return null;
            return (
              <Flex align="center" gap={2} wrap="wrap">
                <Tag color="#38a6cc">{item.model}</Tag>
                {scores.map(score => (
                  <Tag color="blue">{score}</Tag>
                ))}
              </Flex>
            );
          })}
        </Flex>
      </Col>
    </Col>
  );
};

const Usages = forwardRef<TUsagesRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [data, setData] = useState<IUserSessionUsage>({});

  useImperativeHandle(ref, () => ({
    open: (usages: IUserSessionUsage) => {
      setData(usages);
      setOpened(true);
    },
  }));

  const handleClose = () => setOpened(false);

  return (
    <Modal
      title="Usages"
      cancelText="Close"
      open={opened}
      width={800}
      onCancel={handleClose}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      <Usage title="Language model" data={data.llm} />
      <Usage title="Audio" data={data.audio} />
      <Usage title="Costs" data={data.costs} />
    </Modal>
  );
});

export default Usages;
