import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Modal } from 'antd';

import { applyAgreement } from 'api/profile';
import profile, { deleteToken, setAuthorized, setProfile } from 'store/profile';

import Markdown from 'components/markdown';

import { usePolicy } from '../policy';
import { useTerms } from '../terms';

import getData from './data';

import styles from './styles.module.scss';

type TAppliedCallback = () => void;

const agreement: {
  open?: (onApplied?: TAppliedCallback) => void;
  check?: () => boolean;
} = {};

export const useAgreement = () => agreement;

const Agreement: React.FC = () => {
  const { full_name, company } = profile.data || {};

  const policy = usePolicy();
  const terms = useTerms();

  const [opened, setOpened] = useState<boolean>(false);
  const [accepted, setAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const callbackRef = useRef<TAppliedCallback>();

  useEffect(() => {
    agreement.open = onApplied => {
      setOpened(true);
      callbackRef.current = onApplied;
    };
    agreement.check = () => !!getData(location.hostname, '', '');
    return () => {
      agreement.open = undefined;
      agreement.check = undefined;
    };
  }, []);

  const handleOk = async () => {
    try {
      setLoading(true);
      const profile = await applyAgreement();
      if (profile) {
        setProfile(profile);
        setOpened(false);
        callbackRef.current?.();
      }
    } finally {
      setLoading(false);
    }
  };

  const isApplying = !!callbackRef.current;

  const handleClose = () => {
    if (isApplying) {
      deleteToken();
      setAuthorized(false);
    }
    setOpened(false);
  };

  return (
    <Modal
      title="Agreement"
      okText="Next"
      cancelText={isApplying ? 'Disagree' : 'Close'}
      open={opened}
      width={800}
      onCancel={handleClose}
      onOk={handleOk}
      closable={false}
      maskClosable={false}
      okButtonProps={{ disabled: !accepted }}
      confirmLoading={loading}
      footer={(_, extra) => (
        <>
          <extra.CancelBtn />
          {isApplying && <extra.OkBtn />}
        </>
      )}
    >
      <Markdown
        buttons={[
          { key: 'terms', onClick: () => terms.open?.(!company) },
          { key: 'policy', onClick: () => policy.open?.() },
        ]}
        className={styles.markdown}
      >
        {getData(location.hostname, full_name || '', company)}
      </Markdown>
      {isApplying && (
        <>
          <br />
          <Checkbox
            checked={accepted}
            onChange={({ target }) => setAccepted(target.checked)}
          >
            I accept the terms of the agreement
          </Checkbox>
        </>
      )}
    </Modal>
  );
};

export default observer(Agreement);
