/* eslint-disable no-undef */
import { action, observable, runInAction } from 'mobx';

import {
  fetchUsers,
  createUser,
  deleteUser,
  updateUser,
  fetchFlags,
} from 'api/users';
import bots, { loadBots } from 'store/bots';

export type TUserMessageRole = 'assistant' | 'user';

export type TUserSessionStatus = 'active' | 'inactive';

export type IUserInfo = {
  name?: string;
  age?: string;
  gender?: string;
} & Record<string, string | string[]>;

export interface IUserMessage {
  id: string;
  session_id: string;
  role: TUserMessageRole;
  content: string[];
  datetime: string;
}

export interface IUserSessionUsageItem {
  model: string;
  units?: string;
  usage: { input?: number; output?: number; total?: number };
}

export interface IUserSessionUsage {
  llm?: IUserSessionUsageItem[];
  audio?: IUserSessionUsageItem[];
  costs?: IUserSessionUsageItem[];
}

export interface IUserSession {
  id: string;
  last_interaction: string;
  messages: IUserMessage[];
  red_flags?: string[];
  status?: TUserSessionStatus;
  usage?: IUserSessionUsage;
}

export interface IHRInfo {
  user_id: string;
  bot_id: string;
  cv?: string;
  job_description?: string;
  questions_to_ask?: string;
  success_criteria?: string;
  job_position_id?: string;
}

export interface IHRPosition {
  id: string;
  external_id?: string;
  owner_id: string;
  name: string;
  job_description: string;
  questions_to_ask: string;
  success_criteria: string;
}

export interface IHREvaluation {
  name: string;
  reasoning: string;
  score: number;
}

export type TUserStatus = 'invited' | 'active' | 'inactive';

export interface IUserSubscriptionSettings {
  language?: string;
  language_policy_strict?: boolean;
}

export interface IUserSubscription {
  id: string;
  settings?: IUserSubscriptionSettings;
}

export interface IUser {
  id: string;
  email: string;
  full_name?: string;
  company?: string;
  admin: boolean;
  moderator: boolean;
  last_login?: string;
  agreement_accepted?: string;
  public_token: boolean;
  subscriptions?: IUserSubscription[];
  user_bots?: string[];
  account_status: TUserStatus;
  created_at: string;
}

export interface IFlag {
  user_id: string;
  bot_ids: string[];
}

interface IUsers {
  inited: boolean;
  list: IUser[];
  flags: IFlag[];
}

export const defSubscriptionSettings: IUserSubscriptionSettings = {
  language: 'en',
  language_policy_strict: false,
};

const defState: IUsers = {
  inited: false,
  list: [],
  flags: [],
};

const users = observable<IUsers>(defState);

export const clearStore = action(() => Object.assign(users, defState));

export const loadUsers = async () => {
  if (!bots.inited) await loadBots();
  const data = await fetchUsers();
  runInAction(() => {
    users.list = data;
    users.inited = true;
  });
};

export const loadFlags = async () => {
  const data = await fetchFlags();
  runInAction(() => (users.flags = data));
};

export const addUser = async (user: Parameters<typeof createUser>[0]) => {
  const data = await createUser(user);
  typeof data === 'object' && data?.id && (await loadUsers());
  return data;
};

export const editUser = async (
  userId: string,
  user: Parameters<typeof updateUser>[1]
) => {
  const data = await updateUser(userId, user);
  const index = users.list.findIndex(({ id }) => id === userId);
  data && runInAction(() => (users.list[index] = data));
  return !!data;
};

export const removeUser = async (id: string) => {
  const result = await deleteUser(id);
  result &&
    runInAction(() => (users.list = users.list.filter(user => user.id !== id)));
};

export const changeUserStatus = async (
  userId: string,
  account_status: TUserStatus
) => {
  const data = await updateUser(userId, { account_status });
  const index = users.list.findIndex(({ id }) => id === userId);
  data && runInAction(() => (users.list[index] = data));
  return !!data;
};

export const editRole = async (
  userId: string,
  admin: boolean,
  moderator: boolean
) => {
  const data = await updateUser(userId, { admin, moderator });
  const index = users.list.findIndex(({ id }) => id === userId);
  data && runInAction(() => (users.list[index] = data));
  return !!data;
};

export const subscribe = async (
  userId: string,
  subscriptions: IUser['subscriptions']
) => {
  const data = await updateUser(userId, { subscriptions });
  const index = users.list.findIndex(({ id }) => id === userId);
  data && runInAction(() => (users.list[index] = data));
  return !!data;
};

export const resetPassword = async (userId: string, password: string) =>
  !!(await updateUser(userId, { password } as never));

export default users;
