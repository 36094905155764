import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Button,
  ConfigProvider,
  Dropdown,
  notification,
  Tabs,
  ThemeConfig,
  Typography,
} from 'antd';
import {
  BookOutlined,
  ApartmentOutlined,
  UserSwitchOutlined,
  LogoutOutlined,
  RobotOutlined,
  UserOutlined,
  LockOutlined,
  FileTextOutlined,
  ApiOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import profile, {
  deleteToken,
  loadProfile,
  restoreToken,
  setAuthorized,
} from 'store/profile';
import bots from 'store/bots';

import useLabels, { getLabels } from 'labels';
import Password, { usePassword } from 'modals/password';
import Agreement, { useAgreement } from 'modals/agreement';
import Policy from 'modals/policy';
import Terms from 'modals/terms';
import ApiSettings, { useApiSettings } from 'modals/api';
import Guide, { useGuide } from 'modals/guide';

import Auth from 'pages/auth';
import Users from 'pages/users';
import Bots from 'pages/bots';
import Prompts from 'pages/prompts';
import Cubit from 'pages/cubit';

import styles from './App.module.scss';

const tabs: Array<{
  page: string;
  icon: React.ReactNode;
  caption: string;
  component: React.ReactNode;
  admin?: boolean;
}> = [
  {
    page: '/',
    icon: <UserSwitchOutlined />,
    caption: getLabels(location.href).users,
    component: <Users />,
  },
  {
    page: '/bots',
    icon: <RobotOutlined />,
    caption: 'Bots',
    component: <Bots />,
    admin: true,
  },
  // {
  //   page: '/prompts',
  //   icon: <ApartmentOutlined />,
  //   caption: 'Prompts',
  //   component: <Prompts />,
  //   admin: true,
  // },
  // {
  //   page: '/cubit',
  //   icon: <BookOutlined />,
  //   caption: 'Cubit Lessons',
  //   component: <Cubit />,
  //   admin: true,
  // },
];

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [, contextHolder] = notification.useNotification();
  const { isAuthorized, data } = profile;
  const { admin: isAdmin, moderator: isModerator } = data || {
    admin: false,
    moderator: false,
  };

  const [initing, setIniting] = useState<boolean>(true);

  const labels = useLabels();

  useEffect(() => {
    (async () => {
      setIniting(true);
      try {
        const isRestored = restoreToken();
        if (isRestored) {
          const result = await loadProfile(true);
          result && setAuthorized(true);
        }
      } finally {
        setIniting(false);
      }
    })();
  }, []);

  const password = usePassword();
  const handleChangePassword = () =>
    profile.data?.id && password.open?.(profile.data?.id);

  const handleExit = () => {
    deleteToken();
    setAuthorized(false);
  };

  const guide = useGuide();
  const isGuideExist = !!guide.check?.();
  const handleGuide = (inviteButtonText?: string) =>
    guide.open?.(inviteButtonText);

  const agreement = useAgreement();
  const isAgreementExist = !!agreement.check?.();
  console.log({ isAgreementExist, isModerator });
  useEffect(
    () =>
      void (
        !isAdmin &&
        isModerator &&
        isAgreementExist &&
        !profile.data?.agreement_accepted &&
        agreement.open?.(() => {
          const openGuide = () =>
            isGuideExist && handleGuide(`Invite your first ${labels.user}`);
          profile.data?.id && !profile.data?.last_login
            ? password.open?.(profile.data?.id, openGuide)
            : openGuide();
        })
      ),
    [isAgreementExist, isAdmin, isModerator]
  );

  const handleShowAgreement = () => agreement.open?.();

  const apiSettings = useApiSettings();
  const handleApi = () => apiSettings.open?.();

  if (initing) {
    return (
      <div className={styles.root} style={{ padding: 20 }}>
        Loading ...
      </div>
    );
  }

  const themeConfig: ThemeConfig = {
    // algorithm: theme.darkAlgorithm,
    components: {
      Typography: {
        titleMarginBottom: 0,
        titleMarginTop: 0,
      },
    },
  };

  const pages = tabs.filter(({ admin }) => !admin || isAdmin);

  const isHRBotsExist = bots.list.some(({ extra }) => extra?.hr);

  return (
    <ConfigProvider theme={themeConfig}>
      {contextHolder}
      <div className={styles.root}>
        {!isAuthorized ? (
          <Auth />
        ) : (
          <>
            <Tabs
              size="small"
              activeKey={pathname}
              animated={false}
              items={pages.map(({ page: key, icon, caption }) => ({
                key,
                label: (
                  <span>
                    {icon} {caption}
                  </span>
                ),
              }))}
              tabBarExtraContent={
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'password',
                        label: 'Change password',
                        icon: <LockOutlined />,
                        onClick: handleChangePassword,
                      },
                      isAgreementExist
                        ? {
                            key: 'agreement',
                            label: 'Show agreement',
                            icon: <FileTextOutlined />,
                            onClick: handleShowAgreement,
                          }
                        : null,
                      isHRBotsExist
                        ? {
                            key: 'api',
                            label: 'API key',
                            icon: <ApiOutlined />,
                            onClick: handleApi,
                          }
                        : null,
                      isHRBotsExist
                        ? {
                            key: 'guide',
                            label: 'User guide',
                            icon: <QuestionCircleOutlined />,
                            onClick: () => handleGuide(),
                          }
                        : null,
                      { type: 'divider' },
                      {
                        key: 'exit',
                        label: 'Exit',
                        icon: <LogoutOutlined />,
                        onClick: handleExit,
                      },
                    ],
                  }}
                  placement="bottomRight"
                  trigger={['click']}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon={<UserOutlined />}
                    className={styles.avatar}
                  />
                </Dropdown>
              }
              tabBarStyle={{ paddingLeft: 20, margin: 0 }}
              rootClassName={styles.tabs}
              onTabClick={page => navigate(page)}
            />
            <Routes>
              {pages.map(({ page, component }) => (
                <Route key={`page-${page}`} path={page} element={component} />
              ))}
              <Route
                path="*"
                element={
                  <div className={styles.noMatch}>
                    <Typography.Title level={1} type="secondary">
                      404
                    </Typography.Title>
                    <Typography.Paragraph>
                      Select one of the tabs
                    </Typography.Paragraph>
                  </div>
                }
              />
            </Routes>
          </>
        )}
      </div>
      <Password />
      <Agreement />
      <Policy />
      <Terms />
      {isHRBotsExist && <ApiSettings />}
      <Guide />
    </ConfigProvider>
  );
}

export default observer(App);
