import React, {
  forwardRef,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Flex, Modal, Spin, Tag, Typography } from 'antd';

import { download } from 'api';
import { fetchHREvaluation } from 'api/hr';
import { IHREvaluation, IUser } from 'store/users';
import { capitalize } from 'utils/format';

export type TEvaluationRef = {
  open: (chatId: string, user: IUser) => void;
};

const Evaluation = forwardRef<TEvaluationRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IHREvaluation[]>([]);

  const id = useId();
  const chatIdRef = useRef<string>('');
  const userRef = useRef<IUser>();

  const loadData = async (chatId: string) => {
    setLoading(true);
    try {
      const result = await fetchHREvaluation(chatId);
      setData(result);
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: (chatId: string, user: IUser) => {
      chatIdRef.current = chatId;
      userRef.current = user;
      setData([]);
      setOpened(true);
      loadData(chatId);
    },
  }));

  const handleDownload = () =>
    download(
      ['admin_hr', 'download_evaluation'],
      {
        chat_id: chatIdRef.current,
      },
      `${userRef.current?.email}_summary`
    );

  const handleClose = () => setOpened(false);

  return (
    <Modal
      title="Interview Summary"
      okText="Download"
      cancelText="Close"
      open={opened}
      width={800}
      onOk={handleDownload}
      onCancel={handleClose}
    >
      {loading ? (
        <Flex justify="center" align="center">
          <Spin />
        </Flex>
      ) : (
        <Flex vertical gap={15}>
          {data.map(({ name, reasoning, score }, index) => (
            <Flex
              key={`evaluation-${id}-item-${index}`}
              vertical
              align="flex-start"
              gap={5}
            >
              <Typography.Title level={4}>
                {capitalize(name).replaceAll('_', ' ')}
              </Typography.Title>
              <Typography.Text>{reasoning}</Typography.Text>
              <Tag color="blue">Score: {score}</Tag>
            </Flex>
          ))}
        </Flex>
      )}
    </Modal>
  );
});

export default Evaluation;
