/* eslint-disable no-underscore-dangle */
import { get, post, put, remove, TError } from 'api';
import { processError } from 'utils/alert';
import { IBot, IBotLanguage } from 'store/bots';
import { IUser } from 'store/users';

export const fetchBots = async (): Promise<IBot[]> => {
  try {
    const { data } = await get<IBot[]>('admin_bots');
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};

export const createBot = async (
  bot: Omit<IBot, 'id'>
): Promise<IBot | null> => {
  try {
    const { data } = await post<IBot>('admin_bots', bot as never);
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const copyBot = async (
  user_id: string,
  bot_id: string,
  name: string
): Promise<{ bot: IBot; user: IUser } | null> => {
  try {
    const { data } = await post<{ bot: IBot; user: IUser }>(
      ['admin_bots', 'copy_bot'],
      {
        user_id,
        bot_id,
        name,
      }
    );
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const updateBot = async (bot: IBot): Promise<IBot | null> => {
  try {
    const { data } = await put<IBot>(['admin_bots', bot.id], bot);
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const deleteBot = async (id: string): Promise<boolean> => {
  try {
    await remove(['admin_bots', id]);
    return true;
  } catch (error) {
    processError(error as TError);
    return false;
  }
};

export const fetchBotLanguages = async (): Promise<IBotLanguage[]> => {
  try {
    const { data } = await get<IBotLanguage[]>([
      'admin_bots',
      'supported_languages',
    ]);
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};

export const invite = async (
  bot_id: string,
  user_email: string,
  language?: string,
  language_policy_strict?: boolean
): Promise<{ hash: string; user: IUser } | null> => {
  try {
    const { data } = await post<{ hash: string; user: IUser }>(
      ['admin_bots', 'generate_hash'],
      { bot_id, user_email, language, language_policy_strict }
    );
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const sendInvitationEmail = async (
  user_id: string,
  bot_id: string,
  host_uri: string
): Promise<boolean> => {
  try {
    const { data } = await post<string>(['admin_bots', 'send_email'], {
      user_id,
      bot_id,
      host_uri,
    });
    return !!data;
  } catch (error) {
    processError(error as TError);
    return false;
  }
};
