import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Col, Input, Modal, Row, Space } from 'antd';

import { copyToClipboard } from 'utils/data';
import { alertSuccess } from 'utils/alert';
import { generateApiKey } from 'api/profile';
import profile from 'store/profile';

const apiSettings: { open?: () => void } = {};

export const useApiSettings = () => apiSettings;

const ApiSettings: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    apiSettings.open = () => {
      setLoading(false);
      setError(false);
      setValue('');
      setPassword('');
      setOpened(true);
    };
    return () => (apiSettings.open = undefined);
  }, []);

  useEffect(() => setError(false), [password]);

  const handleGenerate = async () => {
    if (!password) return;
    try {
      setLoading(true);
      setError(false);
      const result = await generateApiKey(profile.data?.email || '', password);
      if (result === false) {
        setError(true);
      } else if (result) {
        setValue(result);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = async () =>
    copyToClipboard(value).then(
      result => result && alertSuccess('API key was copied')
    );

  const handleClose = () => {
    setOpened(false);
    setPassword('');
  };

  return (
    <Modal
      title="API key"
      cancelText="Close"
      open={opened}
      onCancel={handleClose}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Input
            placeholder="Your password"
            type="password"
            disabled={!!value || loading}
            status={error ? 'error' : undefined}
            value={password}
            onChange={({ currentTarget }) => setPassword(currentTarget.value)}
          />
        </Col>
        <Col span={24}>
          <Space.Compact block>
            <Input
              placeholder="API key"
              value={value}
              readOnly
              disabled
              onChange={({ currentTarget }) => setValue(currentTarget.value)}
            />
            <Button
              disabled={!password || loading}
              loading={loading}
              onClick={!value ? handleGenerate : handleCopy}
            >
              {!value ? 'Generate' : 'Copy'}
            </Button>
          </Space.Compact>
        </Col>
      </Row>
    </Modal>
  );
};

export default observer(ApiSettings);
