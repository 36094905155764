/* eslint-disable no-undef */
import { action, observable, runInAction } from 'mobx';

import {
  fetchPrompts,
  createPrompt,
  updatePrompt,
  deletePrompt,
} from 'api/prompts';

export interface IPromptHistory {
  content: string;
  datetime: string;
}

export interface IPrompt {
  id: string;
  name: string;
  category: string;
  content: string;
  is_dynamic: boolean;
  prompt_history: Array<IPromptHistory>;
}

interface IPrompts {
  inited: boolean;
  list: IPrompt[];
}

const defState: IPrompts = {
  inited: false,
  list: [],
};

const prompts = observable<IPrompts>(defState);

export const clearStore = action(() => Object.assign(prompts, defState));

export const loadPrompts = async () => {
  const data = await fetchPrompts();
  runInAction(() => {
    prompts.list = data;
    prompts.inited = true;
  });
};

export const addPrompt = async (
  prompt: Omit<IPrompt, 'id' | 'prompt_history'>
) => {
  const data = await createPrompt(prompt);
  data && runInAction(() => (prompts.list = [...prompts.list, data]));
  return !!data;
};

export const editPrompt = async (prompt: Omit<IPrompt, 'prompt_history'>) => {
  const data = await updatePrompt(prompt);
  const index = prompts.list.findIndex(({ id }) => id === prompt.id);
  data && runInAction(() => (prompts.list[index] = data));
  return !!data;
};

export const removePrompt = async (id: string) => {
  const result = await deletePrompt(id);
  result &&
    runInAction(
      () => (prompts.list = prompts.list.filter(prompt => prompt.id !== id))
    );
};

export default prompts;
