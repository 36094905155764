/* eslint-disable no-undef */
import { action, observable, runInAction } from 'mobx';

import {
  deleteCubitLesson,
  fetchCubitLessons,
  sendCubitLesson,
} from 'api/cubit';

export interface ICubitLesson {
  id: string;
  name: string;
  files: Array<string>;
}

interface ICubit {
  inited: boolean;
  lessons: ICubitLesson[];
}

const defState: ICubit = {
  inited: false,
  lessons: [],
};

const cubit = observable<ICubit>(defState);

export const clearStore = action(() => Object.assign(cubit, defState));

export const loadCubitLessons = async () => {
  const data = await fetchCubitLessons();
  runInAction(() => {
    cubit.lessons = data;
    cubit.inited = true;
  });
};

export const addCubitLesson = async (name: string, files: File[]) => {
  const data = await sendCubitLesson(name, files);
  data && runInAction(() => (cubit.lessons = [...cubit.lessons, data]));
  return !!data;
};

export const removeCubitLesson = async (id: string) => {
  const result = await deleteCubitLesson(id);
  result &&
    runInAction(
      () => (cubit.lessons = cubit.lessons.filter(lesson => lesson.id !== id))
    );
};

export default cubit;
