import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Document, Page, pdfjs } from 'react-pdf';
import { OnDocumentLoadSuccess } from 'react-pdf/dist/cjs/shared/types';
import { PDFDocumentProxy } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

import { useInvitation } from 'pages/users/modals/invitation';

import getData from './data';

import styles from './styles.module.scss';

const guide: {
  open?: (inviteButtonText?: string) => void;
  check?: () => boolean;
} = {};

export const useGuide = () => guide;

const Guide: React.FC = () => {
  const { xs } = useBreakpoint();

  const [opened, setOpened] = useState<boolean>(false);
  const [pages, setPages] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const [inviteText, setInviteText] = useState<string>();
  const [reached, setReached] = useState<boolean>(false);

  useEffect(() => {
    guide.open = inviteButtonText => {
      setPage(1);
      setReached(false);
      setInviteText(inviteButtonText);
      setOpened(true);
    };
    guide.check = () => !!getData(location.hostname);
    return () => {
      guide.open = undefined;
      guide.check = undefined;
    };
  }, []);

  const handleClose = () => setOpened(false);

  const pdfRef = useRef<PDFDocumentProxy>();
  const handleLoad: OnDocumentLoadSuccess = pdf => {
    pdfRef.current = pdf;
    setPages(pdf.numPages);
    setPage(1);
  };
  const handlePrevious = () => setPage(value => Math.max(1, --value));
  const handleNext = () => setPage(value => Math.max(++value));

  const [minHeight, setMinHeight] = useState<number>(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const updateSize = async () =>
    setMinHeight(canvasRef.current?.offsetHeight || 0);
  useEffect(() => {
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    pages && page >= pages && setReached(true);
  }, [page, pages]);

  const invitation = useInvitation();
  const handleInvitation = () => {
    handleClose();
    invitation.open?.();
  };

  return (
    <Modal
      title="User guide"
      cancelText="Close"
      open={opened}
      width={1000}
      onCancel={handleClose}
      closable={false}
      maskClosable={false}
      styles={{ body: { minHeight } }}
      footer={(_, extra) => (
        <Space
          className={styles.footer}
          direction={inviteText && xs ? 'vertical' : 'horizontal'}
        >
          {pages > 1 && (
            <Space.Compact>
              <Button
                type="primary"
                disabled={page <= 1}
                onClick={handlePrevious}
              >
                Previous
              </Button>
              <Button
                type="primary"
                disabled={page >= pages}
                onClick={handleNext}
              >
                Next
              </Button>
            </Space.Compact>
          )}
          <Space>
            {inviteText && (
              <Button
                type={reached ? 'primary' : 'default'}
                onClick={handleInvitation}
              >
                {inviteText}
              </Button>
            )}
            <extra.CancelBtn />
          </Space>
        </Space>
      )}
    >
      <Document
        className={styles.pdf}
        file={getData(location.hostname)}
        onLoadSuccess={handleLoad}
      >
        <Page
          canvasRef={canvasRef}
          onLoadSuccess={updateSize}
          pageNumber={page}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      </Document>
    </Modal>
  );
};

export default Guide;
