/* eslint-disable no-underscore-dangle */
import { get, post, put, remove, TError } from 'api';
import { processError } from 'utils/alert';
import { IProfile } from 'store/profile';

export const signIn = async (
  email: string,
  password: string
): Promise<{ profile: IProfile; token: string } | null> => {
  try {
    const { data } = await post<IProfile & { token: string }>(
      ['user', 'login'],
      {
        email,
        password,
      }
    );
    const { token, ...profile } = data;
    return { profile, token };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const fetchProfile = async (
  hideError?: boolean
): Promise<IProfile | null> => {
  try {
    const { data } = await get<IProfile>('user');
    return data;
  } catch (error) {
    !hideError && processError(error as TError);
    return null;
  }
};

export const applyAgreement = async (): Promise<IProfile | null> => {
  try {
    const { data } = await post<IProfile>(['user', 'accept_agreement']);
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const generateApiKey = async (
  email: string,
  password: string
): Promise<string | false | null> => {
  try {
    const { data } = await post<{ api_key: string }>(
      ['api', 'prescreenai', 'generate_api_key'],
      { email, password }
    );
    return data.api_key;
  } catch (error) {
    if ((error as TError).message?.includes('invalid')) {
      return false;
    } else {
      processError(error as TError);
      return null;
    }
  }
};
