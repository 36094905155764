import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Form, Input, Modal, Radio, Space } from 'antd';

import useLabels from 'labels';
import { alertSuccess, alertWarning } from 'utils/alert';
import { generatePassword } from 'utils/data';
import { addUser } from 'store/users';

import styles from '../styles.module.scss';

export type TCreationRef = { open: () => void };
export type TCreationProps = { onSuccess: (userId: string) => void };

interface IFormData {
  email: string;
  password: string;
  role: 'user' | 'moderator' | 'admin';
  name: string;
  company: string;
}

const Creation = forwardRef<TCreationRef, TCreationProps>(
  ({ onSuccess }, ref) => {
    const [opened, setOpened] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm<IFormData>();
    const [role, setRole] = useState<IFormData['role']>('user');

    const isUser = role === 'user';

    const labels = useLabels();

    useEffect(() => {
      form.getFieldError('name').length && form.validateFields(['name']);
    }, [role]);

    useImperativeHandle(ref, () => ({
      open: () => {
        form.resetFields();
        form.setFieldValue('role', 'user');
        setRole('user');
        setOpened(true);
      },
    }));

    const handleClose = () => setOpened(false);

    const handleGenerate = () =>
      form.setFieldValue('password', generatePassword());

    const handleSubmit = async ({
      email,
      password,
      role,
      name,
      company,
    }: IFormData) => {
      try {
        setLoading(true);
        const data = await addUser({
          email,
          password,
          full_name: name,
          company: isUser ? undefined : company,
          admin: role === 'admin',
          moderator: role === 'moderator',
        });
        if (data == 'existed') {
          alertWarning(
            `The ${labels.user.toLowerCase()} with the same email exists`
          );
        } else if (data) {
          alertSuccess(`The ${labels.user.toLowerCase()} is created`);
          handleClose();
          onSuccess(data.id);
        }
      } finally {
        setLoading(false);
      }
    };

    return (
      <Modal
        title="Create new user"
        okText="Create"
        cancelText="Cancel"
        open={opened}
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={handleClose}
        maskClosable={false}
      >
        <Form<IFormData>
          className={styles.creation}
          form={form}
          autoComplete="off"
          labelCol={{ span: 6 }}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[{ type: 'email', required: true }]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item label="Password" required>
            <Space.Compact block className={styles.password}>
              <Form.Item
                name="password"
                rules={[{ required: true, min: 8, max: 30 }]}
                noStyle
              >
                <Input.Password
                  showCount
                  maxLength={30}
                  autoComplete="new-password"
                />
              </Form.Item>
              <Button disabled={loading} onClick={handleGenerate}>
                Generate
              </Button>
            </Space.Compact>
          </Form.Item>
          <Form.Item label="Role" name="role" rules={[{ required: true }]}>
            <Radio.Group onChange={({ target }) => setRole(target.value)}>
              <Radio.Button value="user">User</Radio.Button>
              <Radio.Button value="moderator">Moderator</Radio.Button>
              <Radio.Button value="admin">Admin</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Full name"
            name="name"
            rules={[
              {
                required: !isUser,
                pattern: !isUser ? /\S{3,}/ : undefined,
                validateTrigger: 'onSubmit',
                message: 'Enter at least 3 characters',
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
          <Form.Item label="Company name" name="company">
            <Input
              autoComplete="off"
              disabled={isUser}
              style={{ color: isUser ? 'transparent' : 'inherit' }}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default Creation;
