const urls: Record<string, string> = {
  localhost: '/docs/prescreenai-user-guide-v1.pdf',
  prescreenai: '/docs/prescreenai-user-guide-v1.pdf',
};

const getData = (host: string) =>
  urls[
    Object.entries(urls).find(([key]) =>
      host.toLowerCase().includes(key.toLowerCase())
    )?.[0] as string
  ];

export default getData;
