import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Form, Input, Modal } from 'antd';

import useLabels from 'labels';
import { alertSuccess } from 'utils/alert';
import { editUser, IUser } from 'store/users';

import styles from '../styles.module.scss';

export type TEditingRef = { open: (user: IUser) => void };

interface IFormData {
  name: string;
  company: string;
}

const Editing = forwardRef<TEditingRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<IFormData>();

  const userIdRef = useRef<string>('');

  const labels = useLabels();

  useImperativeHandle(ref, () => ({
    open: user => {
      userIdRef.current = user.id;
      form.setFields([
        { name: 'name', value: user.full_name },
        { name: 'company', value: user.company },
      ]);
      setOpened(true);
    },
  }));

  const handleClose = () => setOpened(false);

  const handleSubmit = async ({ name, company }: IFormData) => {
    try {
      setLoading(true);
      const data = await editUser(userIdRef.current, {
        full_name: name,
        company,
      });
      if (data) {
        alertSuccess(`The ${labels.user.toLowerCase()} data is updated`);
        handleClose();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={`Edit ${labels.user.toLowerCase()} data`}
      okText="Save"
      cancelText="Cancel"
      open={opened}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={handleClose}
      maskClosable={false}
    >
      <Form<IFormData>
        form={form}
        autoComplete="off"
        labelCol={{ span: 6 }}
        onFinish={handleSubmit}
      >
        <Form.Item label="Full name" name="name">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="Company name" name="company">
          <Input autoComplete="off" />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default Editing;
