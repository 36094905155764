import React from 'react';
import { Button, Tooltip } from 'antd';
import { ButtonType } from 'antd/es/button';

type TTooltipButtonProps = {
  title: string;
  tooltip?: string;
  type?: ButtonType;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
};

const TooltipButton = ({
  title,
  tooltip,
  type,
  loading,
  disabled,
  className,
  onClick,
}: TTooltipButtonProps): JSX.Element => {
  const button = (
    <Button
      disabled={disabled}
      type={type}
      loading={loading}
      className={className}
      onClick={onClick}
    >
      {title}
    </Button>
  );

  if (!tooltip || !disabled) {
    return button;
  }

  return (
    <Tooltip trigger="hover" title={tooltip}>
      {button}
    </Tooltip>
  );
};

export default TooltipButton;
