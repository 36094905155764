const texts = (isIndividual: boolean) => ({
  prescreen: isIndividual
    ? `
**1. Introduction**

- **Acceptance of Terms and Conditions:** These are the Terms and Conditions governing the use of this Service (referred to as "[PreScreen AI]" or "Portal") and the agreement that operates between the user (referred to as "You" or "Individual") and Dynanetix, LLC (also referred to as "We," "Us," or "Provider"). These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
- **Conditioned Access:** Your access to and use of the Portal and the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.
- **Binding Agreement:** By registering on the Portal and/or using the Service or expressly agreeing to these terms and conditions, you are bound to this agreement
- **Privacy Policy Compliance:** Your access to and use of the Portal and the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and informs You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

**2. Services Description**

- **Service Overview:** Prescreen AI provides functionality for AI-driven inquiries tailored to candidates' resumes, facilitating evaluation of qualifications and skills.
- **Service Availability:** We endeavor to provide the best service possible, but you acknowledge that disruptions and technical issues may occur, and Dynanetix, LLC is not liable for any disruption, interruption, or delay in the services.

**3. Registration and Account Integrity**

- **Account Creation by Provider:** Accounts on Prescreen AI are created by the Provider based on the information provided by you prior to account setup. It is imperative that you provide accurate, complete, and current information as requested by the Provider to facilitate the creation of your account. By submitting your information, you authorize us to create your account on your behalf.
- **Accuracy of Information:** You are responsible for ensuring the accuracy, completeness, and timeliness of the information you provide to us for the purpose of creating and maintaining your account. You must not furnish any information that is misleading, false, or inaccurate. The integrity of your account relies on the truthfulness and precision of the information you provide.
- **Updates to Information:** Should any of your information change post account creation, it is your responsibility to inform us promptly of any such changes to ensure your account information remains accurate. This is crucial to maintaining the security and integrity of your account and the Service overall.
- **Consequences of Inaccurate Information:** Providing false or misleading information, or failing to update information promptly, may result in the suspension or termination of your account and may affect your ability to access and use the Service. Additionally, you may be held liable for any losses incurred by Dynanetix, LLC or other users due to incorrect or outdated information you have provided.
-

**4 User Conduct and Legal Compliance**

**4.1 Lawful Use of the Service**

- **Requirement for Lawful Use:** You agree to use Prescreen AI solely for lawful purposes as stipulated by local, state, national, and international laws and regulations. You are responsible for ensuring that your use of the Service complies with all laws applicable to you.

**4.2 Prohibited Activities**

- **Illegal Acts:** You may not use the Service for any purpose that is illegal or prohibited by these Terms. This includes, but is not limited to, engaging in any illegal activities, infringing upon or violating the rights of others, and interfering with or disrupting the security, service integrity, or operations of the Service.

**4.3 Consequences of Unlawful Use**

- **Immediate Termination and Cancellation:** Any use of the Service in violation of any applicable local or international law is strictly prohibited. Such violations will result in immediate termination of your access to the Service and cancellation of your account. We reserve the right to terminate your use of the Service and to take any additional legal action deemed necessary.

**4.4 Reporting Unlawful Activities**

- **Duty to Report:** If you become aware of any illegal activity or breach of these Terms by any user, you are encouraged to inform us immediately. We will investigate all reports and take appropriate action, which may include contacting law enforcement authorities.

**4.5 Cooperation with Law Enforcement**

- **Collaboration with Authorities:** We may disclose any information about you to government or law enforcement officials as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process, to protect the property and rights of Dynanetix, LLC or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, unethical, or legally actionable activity.

**4.6 Prohibited Uses of the Service**

- **Restriction on Use for Intended Purpose:** The Service is intended solely for its direct purpose as described in these Terms and the accompanying documentation. You are expressly prohibited from using the Service for any of the following purposes:
  - Obtaining business concepts, ideas, methodologies, or strategies;
  - Conducting research or gathering information for any competitive purpose;
  - Reverse engineering, disassembling, or decompiling any part of the Service, or attempting to access the source code;
  - Replicating the functionality or user experience for any competitive endeavor.

**4.7 Protection of Intellectual Property**

- **Prohibition Against Intellectual Property Infringement:** Using the Service to infringe upon or misappropriate our intellectual property or that of others, including attempting to discover trade secrets or confidential information through the use of the Service, is strictly prohibited. This includes using the Service to develop competing products or services.

**4.8 Enforcement and Penalties**

- **Immediate Consequences for Prohibited Uses:** Any use of the Service that violates these restrictions is subject to immediate termination of your account and legal action. We reserve the right to enforce these terms through civil litigation and cooperation with law enforcement authorities as necessary.
- **Notification of Violations:** If you suspect any misuse of the Service or any act of reverse engineering or competitive abuse, you are required to report it immediately to [Provider's Contact Information].

**4.9 Security Monitoring**

- **Ongoing Monitoring for Compliance:** We may monitor the use of the Service to ensure compliance with these Terms. Any detection of prohibited activities may lead to investigation, suspension of the account, and possible legal actions to protect our intellectual property and business interests.

**5: User-Generated Content**

**5.1 User Content Warranty**

- **Compliance Warranty:** You warrant and represent that your content will comply with these terms and conditions. You are solely responsible for ensuring that your content is of sufficient quality and in an appropriate format for its intended use.

**5.2 Prohibitions on User Content**

- **Lawfulness and Rights Infringement:** Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).
- **Content Restrictions:** Your content, and the use of your content by us in accordance with these terms and conditions, must not:
  - Be libellous, maliciously false, obscene, indecent, or infringe any intellectual property right;
  - Violate any right of confidence, privacy, or data protection legislation;
  - Constitute negligent advice, incite crime, be in contempt of court, or breach court orders;
  - Breach racial or religious hatred or discrimination legislation;
  - Be blasphemous, in breach of official secrets legislation, or any contractual obligations;
  - Depict violence explicitly, be pornographic, lewd, suggestive, or sexually explicit;
  - Be untrue, false, inaccurate, misleading, constitute spam, or be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory, inflammatory;
  - Cause annoyance, inconvenience, or needless anxiety.

**5.3 Liability for User Content**

- **Responsibility and Liability:** You acknowledge that you are solely responsible for any content you supply to us. We will not be liable for any loss, damage, or legal consequences that arise from your content or its use in our Service. This includes any claims related to the accuracy, legality, or any other aspect of your content.
- **No Liability in Customised Products:** Subject to provisions detailed in Section 15.1 of these terms, we will not be liable for any expense, loss, or damage arising out of your content or the incorporation of your content into our products, regardless of whether the content has been transformed or used verbatim.

**5.4 Right to Remove or Modify Content**

- **Content Management:** We reserve the right to edit, remove, or refuse any content provided by users at our sole discretion if we believe it violates these terms, applicable laws, or is otherwise objectionable. We are not obliged to retain or provide you with copies of your content.

**5.5 Third-Party Content Permissions**

- **Consent Requirement:** If your content includes material obtained from third parties, whether individuals or organizations, you must secure full consent from such third parties to share this content with us. You must ensure that this consent covers the unrestricted use of the content by Dynanetix, LLC without any limitations.
- **Warranty of Permission:** By submitting third-party content to our Service, you warrant and represent that you have obtained the necessary permissions, rights, and licenses to use and share the content. This includes the right to grant us the same level of access and usage.
- **Indemnification for Third-Party Claims:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary third-party permissions. This indemnification also covers any misuse or unlawful use of third-party content provided by you.

**5.6 Responsibility for Ensuring Consent**

- **Duty to Verify and Document Consent:** You are responsible for verifying and maintaining records of all consents and permissions related to third-party content. You should be prepared to provide evidence of these permissions to Dynanetix, LLC upon our request to verify compliance with these terms.

**5.7 Handling of Private and Sensitive Information**

- **Consent for Private Information:** If the content obtained from third parties includes private or sensitive information, such as personal names, addresses, emails, phone numbers, etc., you must ensure that such information is obtained with the full discretion and explicit consent of the third party.
- **Informed Third-Party Consent:** Before sharing any personal or sensitive information with Dynanetix, LLC , you must confirm that the third party is fully aware that this information will be shared with us and has explicitly agreed to this sharing. This consent should be documented and should specify the extent to which the information can be used and disclosed by Dynanetix, LLC .
- **Duty to Protect Privacy:** It is your responsibility to protect the privacy rights of third parties by ensuring that the information is collected and shared in compliance with applicable data protection laws. This includes implementing appropriate security measures to protect the data from unauthorized access or breaches.

**5.8 Liability for Breach of Privacy**

- **Indemnification for Privacy Violations:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary consents for private or sensitive information. This includes any violations of data protection laws resulting from your actions.

**5.9 Compliance with Data Protection Regulations**

- **Adherence to Laws:** You must ensure that all activities related to the gathering, storage, and sharing of personal and sensitive information adhere to the highest standards of data protection as dictated by relevant local and international laws.

**6. Intellectual Property**

- **Service Content, Software and Trademarks:** You acknowledge that all intellectual property rights in the service and its original content, features, and functionality are owned by Dynanetix, LLC and are protected.

**6: Intellectual Property Rights**

**6.1 Ownership of Intellectual Property**

- **Provider's Intellectual Property Rights:** Provider's "Intellectual Property Rights" encompass all forms of intellectual property owned by Dynanetix, LLC , wherever in the world these rights may exist. This includes both registrable and unregistrable, registered and unregistered rights, such as copyrights and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, utility models, semi-conductor topography rights, and rights in designs.

**6.2 Rights in Service Content**

- **Exclusive Property:** The Service, along with all its original content, features, and functionality, remains the exclusive property of the Provider and its licensors. This content is protected under the copyright laws, trademark laws, and other intellectual property laws of both the State of Delaware, USA, and other foreign jurisdictions.
- **Prohibited Uses:** Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Provider. Unauthorized use of these intellectual property assets is strictly prohibited and may result in legal action.

**6.3 Protection of Intellectual Property**

- **Infringement and Enforcement:** You agree not to infringe upon the intellectual property rights of the Provider or its licensors. This includes, but is not limited to, refraining from copying, modifying, distributing, selling, or leasing any part of the Service, or using our trademarks without prior written permission from the Provider.
- **Reporting Infringements:** If you become aware of any infringement or potential infringement of the Provider's intellectual property rights, you are encouraged to notify us immediately to allow for appropriate action.

**6.4 Usage Limits and Restrictions**

- **Non-commercial Use:** As specified, the Beta Version of the Service is provided solely for testing and feedback purposes and is not intended for commercial use. Accordingly, any commercial use of the Service, including but not limited to business, revenue-generating activities, or other professional uses, is strictly prohibited.
- **Discretionary Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize, which may include restrictions on the number of transactions, amount of data processed, or the number of accesses per time period. These limits are set at the sole discretion of the Provider and are subject to change without prior notice.

**7: Links to Third-Party Websites and Services**

**7.1 Third-Party Links**

- **Presence of Third-Party Links:** Our Service may contain links to third-party websites or services that are not owned or controlled by Dynanetix, LLC . These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s) or their contents.

**7.2 Disclaimer of Control**

- **No Control Over Third-Parties:** Dynanetix, LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge that Dynanetix, LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

**7.3 User Responsibility**

- **Caution Advised:** We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit. You are responsible for reviewing and abiding by the privacy statements and terms of use posted at any third-party sites linked to or from our Service.

**7.4 Limitation of Liability**

- **Indirect Liability:** You further acknowledge and agree that Dynanetix, LLC is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on, or available from, such websites or resources.

**7.5 Recommendations and Endorsements**

- **No Endorsements:** The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Any reliance you place on such information is therefore strictly at your own risk.

**8: Account Cancellation and Suspension**

**8.1 Cancellation and Suspension by Provider**

- **Provider's Rights:** We reserve the right to:
  - (a) Suspend your account;
  - (b) Cancel your account; and/or
  - (c) Edit your account details, at any time in our sole discretion without notice or explanation.
- **Refunds upon Cancellation:** If we cancel any services for which you have paid and you have not breached these terms and conditions, we will refund to you a pro rata amount of your payment. This amount will be calculated by us using a reasonable methodology.

**8.2 Cancellation by User**

- **How to Cancel:** You may cancel your account at any time using the account control panel provided on the Portal.
- **Refund Policy:** You will not be entitled to any refund upon cancellation by you.
- **Effect of Cancellation:** Account cancellation results in the immediate termination of this Agreement. All provisions of this Agreement that, by their nature, should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability, will survive termination.

**8.3 Consequences of Account Cancellation**

- **Data Retention and Deletion:** Upon termination, we may deactivate your account and archive your account information. Certain information may be retained by us where required by law or for legitimate business purposes.
- **Ongoing Obligations:** Termination of this Agreement does not relieve you of any obligations to pay any fees or costs accrued prior to termination and any other amounts owed by you to us as provided in these Terms and Conditions.

**9: Right of Service Refusal**

**9.1 General Right to Refuse Service**

- **Discretion of Provider:** Under these Terms and Conditions, the Provider reserves the right, at its sole discretion, to refuse Service, either partially or fully, to any Organization or person at any time, whether the Service is currently active or not.
- **No Obligation to Explain:** If the decision to refuse Service is made, the Provider is not obligated to provide any argumentation or explanation regarding the decision to refuse Service.

**9.2 Scope of Service Refusal**

- **Partial Refusal:** The Provider may refuse a certain part of the Service, whether it is associated with a particular subscription or not. This includes, but is not limited to, limiting access to certain features, restricting updates, or denying support services.
- **Complete Refusal:** In cases where the Provider refuses to provide Service entirely to a particular Organization or person that has already accepted these Terms, this action may or may not result in the termination of the agreement, as governed by Section 15 of these Terms and Conditions.

**9.3 Consequences of Refusal**

- **Immediate Effect:** Any refusal of Service will take immediate effect upon the decision by the Provider.
- **Modification of Services:** The Provider reserves the right to modify, suspend, or discontinue any aspect of the Service at any time, including the availability of any Service feature, database, or content.
- **Notification:** While the Provider may not provide a detailed explanation for the refusal of Service, affected users or Organizations will be notified of the refusal decision as soon as practicable.

**9: Limitations and Exclusions of Liability**

**9.1 Limitations of Liability**

- **General Limitations:** To the maximum extent permitted by applicable law, the Provider and its suppliers shall not be liable for any special, incidental, indirect, or consequential damages whatsoever. This includes, but is not limited to, damages for:
  - Loss of profits, loss of data or other information,
  - Business interruption,
  - Personal injury,
  - Loss of privacy arising out of or in any way related to the use of or inability to use the Service,
  - Third-party software and/or third-party hardware used with the Service,
  - Any other matter connected with the Service, even if the Provider or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

**9.2 State Law Variations**

- **Variability by Jurisdiction:** Some states/jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations may not apply to you. In these states, each party's liability will be limited to the greatest extent permitted by law.

**9.3 Cap on Liability**

- **Monetary Cap:** The aggregate liability of the Provider to the Customer under this Agreement in respect of any event or series of related events shall not exceed the greater of:
  - $100.00 (one hundred USD); and
  - The total amount paid and payable by the Customer to the Provider under this Agreement in the 3 month period preceding the commencement of the event or events.

**9.4 Personal Liability**

- **Limitation on Personal Claims:** You accept that we have an interest in limiting the personal liability of our officers and employees. You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the Service (this does not limit or exclude the liability of the Provider itself for the acts and omissions of our officers and employees).

**Chapter 10: Indemnity**

**10.1 Indemnification Obligation**

- **Your Commitment to Indemnify:** You hereby indemnify us and undertake to keep us indemnified against any and all losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute) incurred or suffered by us arising directly or indirectly out of your use of our website or any breach by you of any provision of these terms and conditions.

**Chapter 11: Breaches of These Terms and Conditions**

**11.1 Actions for Breach**

- If you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:
  - Send you one or more formal warnings;
  - Temporarily suspend your access to our Portal;
  - Permanently prohibit you from accessing our Portal;
  - Block computers using your IP address from accessing our Portal;
  - Contact any or all of your internet service providers and request that they block your access to our Portal;
  - Commence legal action against you, whether for breach of contract or otherwise; and/or
  - Suspend or delete your account on our website which also may terminate this Agreement in accordance with Section 15.

**11.2 Circumvention Prohibited**

- **Prohibition on Circumvention:** Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).

This integration provides a comprehensive update and augmentation to your existing Terms and Conditions, ensuring clarity on liability, indemnification, and actions against breaches, which are crucial for protecting both the service provider and the users.

12. General Provisions

Severability: If any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in effect.

13. Contact Information

Queries: If you have any questions about these Terms, please contact Dynanetix, LLC at [support@prescreenai.com](mailto:support@prescreenai.com)

14: Beta Service

14.1 Acknowledgment of Beta Status

**Beta Version Disclaimer:** You acknowledge that Prescreen AI is provided as a "Beta Version" and is made available on an "as is" and "as available" basis for the purpose of providing Dynanetix, LLC with feedback on the quality and usability of the service. The Beta Version may contain bugs, errors, and other problems that could cause system or other failures and data loss.

14.2 Free of Charge

**Service Offered at No Cost:** The Beta Version of the Service is provided free of charge. However, it is subject to usage limitations which may include limits on data usage, number of transactions, access times, or other service metrics.

**Usage Limitations:** The specific limits applicable to your use of the Beta Version are determined at the sole discretion of Dynanetix, LLC and may be amended at any time without notice to you. These limitations are put in place to manage strain on resources and ensure stability of the service for all beta testers.

14.3 Commercial Transition

**Potential for Conversion to Commercial Service:** You understand and agree that the Beta Version may be converted into a commercial (paid) service at any time by Dynanetix, LLC . This transition may require you to accept new or additional terms of service and possibly involve different features or capabilities than those available during the beta period.

**Right to Refuse Commercial Service:** Upon conversion of the Beta Service to a commercial offering, you will have the right to discontinue use of the service if you do not wish to transition to the paid version. Your decision to decline the commercial version will not affect your ability to use the beta service up until the point of conversion.

**14.4 No Commercial Use**

**Non-commercial Use:** The Beta Version is provided for testing and feedback purposes only and is not intended for commercial use. As such, it should not be used for any commercial, business, revenue-generating activities, or any other professional use.

**Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize. These limitations may include, but are not limited to, the number of transactions, amount of data processed, or the number of accesses per time period.

**Discretionary Limits:** The specific limits on usage are set at the sole discretion of Dynanetix, LLC and are subject to change at any time without prior consultation with or notification to you. This is necessary to allow for adjustments based on the operational needs and capacities during the testing phase.

**Restriction Against Commercial Use:** Given that the Beta Version is intended for testing and feedback purposes, it is not designed for commercial use. You must not use the Beta Version for any commercial, business, or revenue-generating activities.

**14.5 Discretionary Changes**

**Changes Without Notice:** Dynanetix, LLC reserves the right to modify the usage limitations and operational features of the Beta Version at any time, without prior consultation or notification to you, as deemed necessary for the ongoing management and development of the service.

**15: "AS IS" and "AS AVAILABLE" Disclaimer**

**15.1 Service Disclaimer**

- **No Warranties Provided:** The Service is provided to You "AS IS" and "AS AVAILABLE," with all faults and defects without warranty of any kind. Dynanetix, LLC , on its own behalf and on behalf of its affiliates and licensors, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise from course of dealing, course of performance, usage, or trade practice.

**15.2 Limitations on Warranties**

- **No Guaranteed Performance:** Without limiting the foregoing, neither Dynanetix, LLC nor any of its service providers makes any representation or warranty of any kind, express or implied:
  - (i) as to the operation or availability of the Service, or the information, content, and materials included thereon;
  - (ii) that the Service will be uninterrupted or error-free;
  - (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service;
  - (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of Dynanetix, LLC are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.

**15.3 Applicability of Disclaimers**

- **Jurisdictional Variations:** Some jurisdictions do not allow the exclusion of certain warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. However, in such cases, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

**16: Governing Law, Dispute Resolution**

**16.1 Governing Law**

- **Applicable Law:** These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of New York, USA. Your use of the Service may also be subject to other local, state, national, or international laws.

**16.2 Dispute Resolution**

- **Arbitration Agreement:** Any disputes arising out of or in connection with this Agreement, including any questions regarding its existence, validity, or termination, shall be resolved through arbitration according to the American Arbitration Rules by three arbitrators. The arbitration shall take place in New York City, and the procedural law of this place shall apply where the Rules are silent. The arbitration proceedings shall be conducted in English, and the award shall be final and binding on the parties and enforceable in any court of competent jurisdiction.

**16.3 Waiver of Jury Trial**

- **Jury Trial Waiver:** You and Dynanetix, LLC waive any right to a trial by jury with respect to any dispute or legal action arising from or in connection with these Terms and Conditions.
        `
    : `
**1. Introduction**

- **Acceptance of Terms and Conditions:** These are the Terms and Conditions governing the use of this Service (referred to as "[PreScreen.AI]" or "Portal") and the agreement that operates between the user (referred to as "You" or "Organization") and Dynanetix, LLC (also referred to as "We," "Us," or "Provider"). These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
- **Conditioned Access:** Your access to and use of the Portal and the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.
- **Representation of Organization:** You fully acknowledge that the Service is intended for organizational use and not for personal use. You represent that when using the Service, you are over the age of 18 and act on behalf of an Organization. The Company does not permit those under 18 to use the Service and prohibits individual use for personal or any other reasons.
- **Binding Agreement:** If you register on the Portal and/or use the Service or expressly agree to these terms and conditions in the course of a business or other organizational project, then by so doing you bind both:
  1. Yourself; and
  2. The Organization to these terms and conditions, and in these circumstances, references to "you" in these terms and conditions are to both the individual user and the relevant person, company, or legal entity (Organization).
- **Privacy Policy Compliance:** Your access to and use of the Portal and the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and informs You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

**2. Services Description**

- **Service Overview:** Prescreen AI provides functionality for AI-driven inquiries tailored to candidates' resumes, facilitating evaluation of qualifications and skills.
- **Service Availability:** We endeavor to provide the best service possible, but you acknowledge that disruptions and technical issues may occur, and Dynanetix, LLC is not liable for any disruption, interruption, or delay in the services.

**3. Registration and Account Integrity**

- **Account Creation by Provider:** Accounts on Prescreen AI are created by the Provider based on the information provided by you prior to account setup. It is imperative that you provide accurate, complete, and current information as requested by the Provider to facilitate the creation of your account. By submitting your information, you authorize us to create your account on your behalf.
- **Accuracy of Information:** You are responsible for ensuring the accuracy, completeness, and timeliness of the information you provide to us for the purpose of creating and maintaining your account. You must not furnish any information that is misleading, false, or inaccurate. The integrity of your account relies on the truthfulness and precision of the information you provide.
- **Updates to Information:** Should any of your information change post account creation, it is your responsibility to inform us promptly of any such changes to ensure your account information remains accurate. This is crucial to maintaining the security and integrity of your account and the Service overall.
- **Consequences of Inaccurate Information:** Providing false or misleading information, or failing to update information promptly, may result in the suspension or termination of your account and may affect your ability to access and use the Service. Additionally, you may be held liable for any losses incurred by Dynanetix, LLC or other users due to incorrect or outdated information you have provided.

**4 User Conduct and Legal Compliance**

**4.1 Lawful Use of the Service**

- **Requirement for Lawful Use:** You agree to use Prescreen AI solely for lawful purposes as stipulated by local, state, national, and international laws and regulations. You are responsible for ensuring that your use of the Service complies with all laws applicable to you.

**4.2 Prohibited Activities**

- **Illegal Acts:** You may not use the Service for any purpose that is illegal or prohibited by these Terms. This includes, but is not limited to, engaging in any illegal activities, infringing upon or violating the rights of others, and interfering with or disrupting the security, service integrity, or operations of the Service.

**4.3 Consequences of Unlawful Use**

- **Immediate Termination and Cancellation:** Any use of the Service in violation of any applicable local or international law is strictly prohibited. Such violations will result in immediate termination of your access to the Service and cancellation of your account. We reserve the right to terminate your use of the Service and to take any additional legal action deemed necessary.

**4.4 Reporting Unlawful Activities**

- **Duty to Report:** If you become aware of any illegal activity or breach of these Terms by any user, you are encouraged to inform us immediately. We will investigate all reports and take appropriate action, which may include contacting law enforcement authorities.

**4.5 Cooperation with Law Enforcement**

- **Collaboration with Authorities:** We may disclose any information about you to government or law enforcement officials as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process, to protect the property and rights of Dynanetix, LLC or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, unethical, or legally actionable activity.

**4.6 Prohibited Uses of the Service**

- **Restriction on Use for Intended Purpose:** The Service is intended solely for its direct purpose as described in these Terms and the accompanying documentation. You are expressly prohibited from using the Service for any of the following purposes:
  - Obtaining business concepts, ideas, methodologies, or strategies;
  - Conducting research or gathering information for any competitive purpose;
  - Reverse engineering, disassembling, or decompiling any part of the Service, or attempting to access the source code;
  - Replicating the functionality or user experience for any competitive endeavor.

**4.7 Protection of Intellectual Property**

- **Prohibition Against Intellectual Property Infringement:** Using the Service to infringe upon or misappropriate our intellectual property or that of others, including attempting to discover trade secrets or confidential information through the use of the Service, is strictly prohibited. This includes using the Service to develop competing products or services.

**4.8 Enforcement and Penalties**

- **Immediate Consequences for Prohibited Uses:** Any use of the Service that violates these restrictions is subject to immediate termination of your account and legal action. We reserve the right to enforce these terms through civil litigation and cooperation with law enforcement authorities as necessary.
- **Notification of Violations:** If you suspect any misuse of the Service or any act of reverse engineering or competitive abuse, you are required to report it immediately to [Provider's Contact Information].

**4.9 Security Monitoring**

- **Ongoing Monitoring for Compliance:** We may monitor the use of the Service to ensure compliance with these Terms. Any detection of prohibited activities may lead to investigation, suspension of the account, and possible legal actions to protect our intellectual property and business interests.

**5: User-Generated Content**

**5.1 User Content Warranty**

- **Compliance Warranty:** You warrant and represent that your content will comply with these terms and conditions. You are solely responsible for ensuring that your content is of sufficient quality and in an appropriate format for its intended use.

**5.2 Prohibitions on User Content**

- **Lawfulness and Rights Infringement:** Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).
- **Content Restrictions:** Your content, and the use of your content by us in accordance with these terms and conditions, must not:
  - Be libellous, maliciously false, obscene, indecent, or infringe any intellectual property right;
  - Violate any right of confidence, privacy, or data protection legislation;
  - Constitute negligent advice, incite crime, be in contempt of court, or breach court orders;
  - Breach racial or religious hatred or discrimination legislation;
  - Be blasphemous, in breach of official secrets legislation, or any contractual obligations;
  - Depict violence explicitly, be pornographic, lewd, suggestive, or sexually explicit;
  - Be untrue, false, inaccurate, misleading, constitute spam, or be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory, inflammatory;
  - Cause annoyance, inconvenience, or needless anxiety.

**5.3 Liability for User Content**

- **Responsibility and Liability:** You acknowledge that you are solely responsible for any content you supply to us. We will not be liable for any loss, damage, or legal consequences that arise from your content or its use in our Service. This includes any claims related to the accuracy, legality, or any other aspect of your content.
- **No Liability in Customised Products:** Subject to provisions detailed in Section 15.1 of these terms, we will not be liable for any expense, loss, or damage arising out of your content or the incorporation of your content into our products, regardless of whether the content has been transformed or used verbatim.

**5.4 Right to Remove or Modify Content**

- **Content Management:** We reserve the right to edit, remove, or refuse any content provided by users at our sole discretion if we believe it violates these terms, applicable laws, or is otherwise objectionable. We are not obliged to retain or provide you with copies of your content.

**5.5 Third-Party Content Permissions**

- **Consent Requirement:** If your content includes material obtained from third parties, whether individuals or organizations, you must secure full consent from such third parties to share this content with us. You must ensure that this consent covers the unrestricted use of the content by Dynanetix, LLC without any limitations.
- **Warranty of Permission:** By submitting third-party content to our Service, you warrant and represent that you have obtained the necessary permissions, rights, and licenses to use and share the content. This includes the right to grant us the same level of access and usage.
- **Indemnification for Third-Party Claims:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary third-party permissions. This indemnification also covers any misuse or unlawful use of third-party content provided by you.

**5.6 Responsibility for Ensuring Consent**

- **Duty to Verify and Document Consent:** You are responsible for verifying and maintaining records of all consents and permissions related to third-party content. You should be prepared to provide evidence of these permissions to Dynanetix, LLC upon our request to verify compliance with these terms.

**5.7 Handling of Private and Sensitive Information**

- **Consent for Private Information:** If the content obtained from third parties includes private or sensitive information, such as personal names, addresses, emails, phone numbers, etc., you must ensure that such information is obtained with the full discretion and explicit consent of the third party.
- **Informed Third-Party Consent:** Before sharing any personal or sensitive information with Dynanetix, LLC , you must confirm that the third party is fully aware that this information will be shared with us and has explicitly agreed to this sharing. This consent should be documented and should specify the extent to which the information can be used and disclosed by Dynanetix, LLC .
- **Duty to Protect Privacy:** It is your responsibility to protect the privacy rights of third parties by ensuring that the information is collected and shared in compliance with applicable data protection laws. This includes implementing appropriate security measures to protect the data from unauthorized access or breaches.

**5.8 Liability for Breach of Privacy**

- **Indemnification for Privacy Violations:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary consents for private or sensitive information. This includes any violations of data protection laws resulting from your actions.

**5.9 Compliance with Data Protection Regulations**

- **Adherence to Laws:** You must ensure that all activities related to the gathering, storage, and sharing of personal and sensitive information adhere to the highest standards of data protection as dictated by relevant local and international laws.

**6. Intellectual Property**

- **Service Content, Software and Trademarks:** You acknowledge that all intellectual property rights in the service and its original content, features, and functionality are owned by Dynanetix, LLC and are protected.

**6: Intellectual Property Rights**

**6.1 Ownership of Intellectual Property**

- **Provider's Intellectual Property Rights:** Provider's "Intellectual Property Rights" encompass all forms of intellectual property owned by Dynanetix, LLC , wherever in the world these rights may exist. This includes both registrable and unregistrable, registered and unregistered rights, such as copyrights and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, utility models, semi-conductor topography rights, and rights in designs.

**6.2 Rights in Service Content**

- **Exclusive Property:** The Service, along with all its original content, features, and functionality, remains the exclusive property of the Provider and its licensors. This content is protected under the copyright laws, trademark laws, and other intellectual property laws of both the State of Delaware, USA, and other foreign jurisdictions.
- **Prohibited Uses:** Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Provider. Unauthorized use of these intellectual property assets is strictly prohibited and may result in legal action.

**6.3 Protection of Intellectual Property**

- **Infringement and Enforcement:** You agree not to infringe upon the intellectual property rights of the Provider or its licensors. This includes, but is not limited to, refraining from copying, modifying, distributing, selling, or leasing any part of the Service, or using our trademarks without prior written permission from the Provider.
- **Reporting Infringements:** If you become aware of any infringement or potential infringement of the Provider's intellectual property rights, you are encouraged to notify us immediately to allow for appropriate action.

**6.4 Usage Limits and Restrictions**

- **Non-commercial Use:** As specified, the Beta Version of the Service is provided solely for testing and feedback purposes and is not intended for commercial use. Accordingly, any commercial use of the Service, including but not limited to business, revenue-generating activities, or other professional uses, is strictly prohibited.
- **Discretionary Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize, which may include restrictions on the number of transactions, amount of data processed, or the number of accesses per time period. These limits are set at the sole discretion of the Provider and are subject to change without prior notice.

**7: Links to Third-Party Websites and Services**

**7.1 Third-Party Links**

- **Presence of Third-Party Links:** Our Service may contain links to third-party websites or services that are not owned or controlled by Dynanetix, LLC . These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s) or their contents.

**7.2 Disclaimer of Control**

- **No Control Over Third-Parties:** Dynanetix, LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge that Dynanetix, LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

**7.3 User Responsibility**

- **Caution Advised:** We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit. You are responsible for reviewing and abiding by the privacy statements and terms of use posted at any third-party sites linked to or from our Service.

**7.4 Limitation of Liability**

- **Indirect Liability:** You further acknowledge and agree that Dynanetix, LLC is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on, or available from, such websites or resources.

**7.5 Recommendations and Endorsements**

- **No Endorsements:** The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Any reliance you place on such information is therefore strictly at your own risk.

**8: Account Cancellation and Suspension**

**8.1 Cancellation and Suspension by Provider**

- **Provider's Rights:** We reserve the right to:
  - (a) Suspend your account;
  - (b) Cancel your account; and/or
  - (c) Edit your account details, at any time in our sole discretion without notice or explanation.
- **Refunds upon Cancellation:** If we cancel any services for which you have paid and you have not breached these terms and conditions, we will refund to you a pro rata amount of your payment. This amount will be calculated by us using a reasonable methodology.

**8.2 Cancellation by User**

- **How to Cancel:** You may cancel your account at any time using the account control panel provided on the Portal.
- **Refund Policy:** You will not be entitled to any refund upon cancellation by you.
- **Effect of Cancellation:** Account cancellation results in the immediate termination of this Agreement. All provisions of this Agreement that, by their nature, should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability, will survive termination.

**8.3 Consequences of Account Cancellation**

- **Data Retention and Deletion:** Upon termination, we may deactivate your account and archive your account information. Certain information may be retained by us where required by law or for legitimate business purposes.
- **Ongoing Obligations:** Termination of this Agreement does not relieve you of any obligations to pay any fees or costs accrued prior to termination and any other amounts owed by you to us as provided in these Terms and Conditions.

**9: Right of Service Refusal**

**9.1 General Right to Refuse Service**

- **Discretion of Provider:** Under these Terms and Conditions, the Provider reserves the right, at its sole discretion, to refuse Service, either partially or fully, to any Organization or person at any time, whether the Service is currently active or not.
- **No Obligation to Explain:** If the decision to refuse Service is made, the Provider is not obligated to provide any argumentation or explanation regarding the decision to refuse Service.

**9.2 Scope of Service Refusal**

- **Partial Refusal:** The Provider may refuse a certain part of the Service, whether it is associated with a particular subscription or not. This includes, but is not limited to, limiting access to certain features, restricting updates, or denying support services.
- **Complete Refusal:** In cases where the Provider refuses to provide Service entirely to a particular Organization that has already accepted these Terms, this action may or may not result in the termination of the agreement, as governed by Section 15 of these Terms and Conditions.

**9.3 Consequences of Refusal**

- **Immediate Effect:** Any refusal of Service will take immediate effect upon the decision by the Provider.
- **Modification of Services:** The Provider reserves the right to modify, suspend, or discontinue any aspect of the Service at any time, including the availability of any Service feature, database, or content.
- **Notification:** While the Provider may not provide a detailed explanation for the refusal of Service, affected users or Organizations will be notified of the refusal decision as soon as practicable.

**9: Limitations and Exclusions of Liability**

**9.1 Limitations of Liability**

- **General Limitations:** To the maximum extent permitted by applicable law, the Provider and its suppliers shall not be liable for any special, incidental, indirect, or consequential damages whatsoever. This includes, but is not limited to, damages for:
  - Loss of profits, loss of data or other information,
  - Business interruption,
  - Personal injury,
  - Loss of privacy arising out of or in any way related to the use of or inability to use the Service,
  - Third-party software and/or third-party hardware used with the Service,
  - Any other matter connected with the Service, even if the Provider or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

**9.2 State Law Variations**

- **Variability by Jurisdiction:** Some states/jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations may not apply to you. In these states, each party's liability will be limited to the greatest extent permitted by law.

**9.3 Cap on Liability**

- **Monetary Cap:** The aggregate liability of the Provider to the Customer under this Agreement in respect of any event or series of related events shall not exceed the greater of:
  - $100.00 (one hundred USD); and
  - The total amount paid and payable by the Customer to the Provider under this Agreement in the 3 month period preceding the commencement of the event or events.

**9.4 Personal Liability**

- **Limitation on Personal Claims:** You accept that we have an interest in limiting the personal liability of our officers and employees. You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the Service (this does not limit or exclude the liability of the Provider itself for the acts and omissions of our officers and employees).

**10: Indemnity**

**10.1 Indemnification Obligation**

- **Your Commitment to Indemnify:** You hereby indemnify us and undertake to keep us indemnified against any and all losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute) incurred or suffered by us arising directly or indirectly out of your use of our website or any breach by you of any provision of these terms and conditions.

**11: Breaches of These Terms and Conditions**

**11.1 Actions for Breach**

- If you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:
  - Send you one or more formal warnings;
  - Temporarily suspend your access to our Portal;
  - Permanently prohibit you from accessing our Portal;
  - Block computers using your IP address from accessing our Portal;
  - Contact any or all of your internet service providers and request that they block your access to our Portal;
  - Commence legal action against you, whether for breach of contract or otherwise; and/or
  - Suspend or delete your account on our website which also may terminate this Agreement in accordance with Section 15.

**11.2 Circumvention Prohibited**

- **Prohibition on Circumvention:** Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).

This integration provides a comprehensive update and augmentation to your existing Terms and Conditions, ensuring clarity on liability, indemnification, and actions against breaches, which are crucial for protecting both the service provider and the users.

**12: General Provisions**

Severability: If any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in effect.

**13: Contact Information**

Queries: If you have any questions about these Terms, please contact Dynanetix, LLC by [support@prescreenai.com](mailto:support@prescreenai.com) .

**14: Beta Service**

**14.1 Acknowledgment of Beta Status**

**Beta Version Disclaimer:** You acknowledge that Prescreen AI is provided as a "Beta Version" and is made available on an "as is" and "as available" basis for the purpose of providing Dynanetix, LLC with feedback on the quality and usability of the service. The Beta Version may contain bugs, errors, and other problems that could cause system or other failures and data loss.

**14.2 Free of Charge**

**Service Offered at No Cost:** The Beta Version of the Service is provided free of charge. However, it is subject to usage limitations which may include limits on data usage, number of transactions, access times, or other service metrics.

**Usage Limitations:** The specific limits applicable to your use of the Beta Version are determined at the sole discretion of Dynanetix, LLC and may be amended at any time without notice to you. These limitations are put in place to manage strain on resources and ensure stability of the service for all beta testers.

**14.3 Commercial Transition**

**Potential for Conversion to Commercial Service:** You understand and agree that the Beta Version may be converted into a commercial (paid) service at any time by Dynanetix, LLC . This transition may require you to accept new or additional terms of service and possibly involve different features or capabilities than those available during the beta period.

**Right to Refuse Commercial Service:** Upon conversion of the Beta Service to a commercial offering, you will have the right to discontinue use of the service if you do not wish to transition to the paid version. Your decision to decline the commercial version will not affect your ability to use the beta service up until the point of conversion.

**14.4 No Commercial Use**

**Non-commercial Use:** The Beta Version is provided for testing and feedback purposes only and is not intended for commercial use. As such, it should not be used for any commercial, business, revenue-generating activities, or any other professional use.

**Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize. These limitations may include, but are not limited to, the number of transactions, amount of data processed, or the number of accesses per time period.

**Discretionary Limits:** The specific limits on usage are set at the sole discretion of Dynanetix, LLC and are subject to change at any time without prior consultation with or notification to you. This is necessary to allow for adjustments based on the operational needs and capacities during the testing phase.

**Restriction Against Commercial Use:** Given that the Beta Version is intended for testing and feedback purposes, it is not designed for commercial use. You must not use the Beta Version for any commercial, business, or revenue-generating activities.

**14.5 Discretionary Changes**

**Changes Without Notice:** Dynanetix, LLC reserves the right to modify the usage limitations and operational features of the Beta Version at any time, without prior consultation or notification to you, as deemed necessary for the ongoing management and development of the service.

**15: "AS IS" and "AS AVAILABLE" Disclaimer**

**15.1 Service Disclaimer**

- **No Warranties Provided:** The Service is provided to You "AS IS" and "AS AVAILABLE," with all faults and defects without warranty of any kind. Dynanetix, LLC, on its own behalf and on behalf of its affiliates and licensors, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise from course of dealing, course of performance, usage, or trade practice.

**15.2 Limitations on Warranties**

- **No Guaranteed Performance:** Without limiting the foregoing, neither Dynanetix, LLC nor any of its service providers makes any representation or warranty of any kind, express or implied:
  - (i) as to the operation or availability of the Service, or the information, content, and materials included thereon;
  - (ii) that the Service will be uninterrupted or error-free;
  - (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service;
  - (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of Dynanetix, LLC are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.

**15.3 Applicability of Disclaimers**

- **Jurisdictional Variations:** Some jurisdictions do not allow the exclusion of certain warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. However, in such cases, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

**16: Governing Law, Dispute Resolution**

**16.1 Governing Law**

- **Applicable Law:** These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of New York, USA. Your use of the Service may also be subject to other local, state, national, or international laws.

**16.2 Dispute Resolution**

- **Arbitration Agreement:** Any disputes arising out of or in connection with this Agreement, including any questions regarding its existence, validity, or termination, shall be resolved through arbitration according to the American Arbitration Rules by three arbitrators. The arbitration shall take place in New York City, and the procedural law of this place shall apply where the Rules are silent. The arbitration proceedings shall be conducted in English, and the award shall be final and binding on the parties and enforceable in any court of competent jurisdiction.

**16.3 Waiver of Jury Trial**

- **Jury Trial Waiver:** Both the Organization and Dynanetix, LLC waive any right to a trial by jury with respect to any dispute or legal action arising from or in connection with these Terms and Conditions.
        `,
  sifu: isIndividual
    ? `
**1. Introduction**

- **Acceptance of Terms and Conditions:** These are the Terms and Conditions governing the use of this Service (referred to as "[Sifu One]" or "Portal") and the agreement that operates between the user (referred to as "You" or "Individual") and Dynanetix, LLC (also referred to as "We," "Us," or "Provider"). These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
- **Conditioned Access:** Your access to and use of the Portal and the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.
- **Binding Agreement:** By registering on the Portal and/or using the Service or expressly agreeing to these terms and conditions, you are bound to this agreement
- **Privacy Policy Compliance:** Your access to and use of the Portal and the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and informs You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

**2. Services Description**

- **Service Overview:** Sifu One provides functionality for AI-driven inquiries tailored to users' needs.
- **Service Availability:** We endeavor to provide the best service possible, but you acknowledge that disruptions and technical issues may occur, and Dynanetix, LLC is not liable for any disruption, interruption, or delay in the services.

**3. Registration and Account Integrity**

- **Account Creation by Provider:** Accounts on Sifu One are created by the Provider based on the information provided by you prior to account setup. It is imperative that you provide accurate, complete, and current information as requested by the Provider to facilitate the creation of your account. By submitting your information, you authorize us to create your account on your behalf.
- **Accuracy of Information:** You are responsible for ensuring the accuracy, completeness, and timeliness of the information you provide to us for the purpose of creating and maintaining your account. You must not furnish any information that is misleading, false, or inaccurate. The integrity of your account relies on the truthfulness and precision of the information you provide.
- **Updates to Information:** Should any of your information change post account creation, it is your responsibility to inform us promptly of any such changes to ensure your account information remains accurate. This is crucial to maintaining the security and integrity of your account and the Service overall.
- **Consequences of Inaccurate Information:** Providing false or misleading information, or failing to update information promptly, may result in the suspension or termination of your account and may affect your ability to access and use the Service. Additionally, you may be held liable for any losses incurred by Dynanetix, LLC or other users due to incorrect or outdated information you have provided.
-

**4 User Conduct and Legal Compliance**

**4.1 Lawful Use of the Service**

- **Requirement for Lawful Use:** You agree to use Sifu One solely for lawful purposes as stipulated by local, state, national, and international laws and regulations. You are responsible for ensuring that your use of the Service complies with all laws applicable to you.

**4.2 Prohibited Activities**

- **Illegal Acts:** You may not use the Service for any purpose that is illegal or prohibited by these Terms. This includes, but is not limited to, engaging in any illegal activities, infringing upon or violating the rights of others, and interfering with or disrupting the security, service integrity, or operations of the Service.

**4.3 Consequences of Unlawful Use**

- **Immediate Termination and Cancellation:** Any use of the Service in violation of any applicable local or international law is strictly prohibited. Such violations will result in immediate termination of your access to the Service and cancellation of your account. We reserve the right to terminate your use of the Service and to take any additional legal action deemed necessary.

**4.4 Reporting Unlawful Activities**

- **Duty to Report:** If you become aware of any illegal activity or breach of these Terms by any user, you are encouraged to inform us immediately. We will investigate all reports and take appropriate action, which may include contacting law enforcement authorities.

**4.5 Cooperation with Law Enforcement**

- **Collaboration with Authorities:** We may disclose any information about you to government or law enforcement officials as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process, to protect the property and rights of Dynanetix, LLC or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, unethical, or legally actionable activity.

**4.6 Prohibited Uses of the Service**

- **Restriction on Use for Intended Purpose:** The Service is intended solely for its direct purpose as described in these Terms and the accompanying documentation. You are expressly prohibited from using the Service for any of the following purposes:
  - Obtaining business concepts, ideas, methodologies, or strategies;
  - Conducting research or gathering information for any competitive purpose;
  - Reverse engineering, disassembling, or decompiling any part of the Service, or attempting to access the source code;
  - Replicating the functionality or user experience for any competitive endeavor.

**4.7 Protection of Intellectual Property**

- **Prohibition Against Intellectual Property Infringement:** Using the Service to infringe upon or misappropriate our intellectual property or that of others, including attempting to discover trade secrets or confidential information through the use of the Service, is strictly prohibited. This includes using the Service to develop competing products or services.

**4.8 Enforcement and Penalties**

- **Immediate Consequences for Prohibited Uses:** Any use of the Service that violates these restrictions is subject to immediate termination of your account and legal action. We reserve the right to enforce these terms through civil litigation and cooperation with law enforcement authorities as necessary.
- **Notification of Violations:** If you suspect any misuse of the Service or any act of reverse engineering or competitive abuse, you are required to report it immediately to [Provider's Contact Information].

**4.9 Security Monitoring**

- **Ongoing Monitoring for Compliance:** We may monitor the use of the Service to ensure compliance with these Terms. Any detection of prohibited activities may lead to investigation, suspension of the account, and possible legal actions to protect our intellectual property and business interests.

**5: User-Generated Content**

**5.1 User Content Warranty**

- **Compliance Warranty:** You warrant and represent that your content will comply with these terms and conditions. You are solely responsible for ensuring that your content is of sufficient quality and in an appropriate format for its intended use.

**5.2 Prohibitions on User Content**

- **Lawfulness and Rights Infringement:** Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).
- **Content Restrictions:** Your content, and the use of your content by us in accordance with these terms and conditions, must not:
  - Be libellous, maliciously false, obscene, indecent, or infringe any intellectual property right;
  - Violate any right of confidence, privacy, or data protection legislation;
  - Constitute negligent advice, incite crime, be in contempt of court, or breach court orders;
  - Breach racial or religious hatred or discrimination legislation;
  - Be blasphemous, in breach of official secrets legislation, or any contractual obligations;
  - Depict violence explicitly, be pornographic, lewd, suggestive, or sexually explicit;
  - Be untrue, false, inaccurate, misleading, constitute spam, or be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory, inflammatory;
  - Cause annoyance, inconvenience, or needless anxiety.

**5.3 Liability for User Content**

- **Responsibility and Liability:** You acknowledge that you are solely responsible for any content you supply to us. We will not be liable for any loss, damage, or legal consequences that arise from your content or its use in our Service. This includes any claims related to the accuracy, legality, or any other aspect of your content.
- **No Liability in Customised Products:** Subject to provisions detailed in Section 15.1 of these terms, we will not be liable for any expense, loss, or damage arising out of your content or the incorporation of your content into our products, regardless of whether the content has been transformed or used verbatim.

**5.4 Right to Remove or Modify Content**

- **Content Management:** We reserve the right to edit, remove, or refuse any content provided by users at our sole discretion if we believe it violates these terms, applicable laws, or is otherwise objectionable. We are not obliged to retain or provide you with copies of your content.

**5.5 Third-Party Content Permissions**

- **Consent Requirement:** If your content includes material obtained from third parties, whether individuals or organizations, you must secure full consent from such third parties to share this content with us. You must ensure that this consent covers the unrestricted use of the content by Dynanetix, LLC without any limitations.
- **Warranty of Permission:** By submitting third-party content to our Service, you warrant and represent that you have obtained the necessary permissions, rights, and licenses to use and share the content. This includes the right to grant us the same level of access and usage.
- **Indemnification for Third-Party Claims:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary third-party permissions. This indemnification also covers any misuse or unlawful use of third-party content provided by you.

**5.6 Responsibility for Ensuring Consent**

- **Duty to Verify and Document Consent:** You are responsible for verifying and maintaining records of all consents and permissions related to third-party content. You should be prepared to provide evidence of these permissions to Dynanetix, LLC upon our request to verify compliance with these terms.

**5.7 Handling of Private and Sensitive Information**

- **Consent for Private Information:** If the content obtained from third parties includes private or sensitive information, such as personal names, addresses, emails, phone numbers, etc., you must ensure that such information is obtained with the full discretion and explicit consent of the third party.
- **Informed Third-Party Consent:** Before sharing any personal or sensitive information with Dynanetix, LLC , you must confirm that the third party is fully aware that this information will be shared with us and has explicitly agreed to this sharing. This consent should be documented and should specify the extent to which the information can be used and disclosed by Dynanetix, LLC .
- **Duty to Protect Privacy:** It is your responsibility to protect the privacy rights of third parties by ensuring that the information is collected and shared in compliance with applicable data protection laws. This includes implementing appropriate security measures to protect the data from unauthorized access or breaches.

**5.8 Liability for Breach of Privacy**

- **Indemnification for Privacy Violations:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary consents for private or sensitive information. This includes any violations of data protection laws resulting from your actions.

**5.9 Compliance with Data Protection Regulations**

- **Adherence to Laws:** You must ensure that all activities related to the gathering, storage, and sharing of personal and sensitive information adhere to the highest standards of data protection as dictated by relevant local and international laws.

**6. Intellectual Property**

- **Service Content, Software and Trademarks:** You acknowledge that all intellectual property rights in the service and its original content, features, and functionality are owned by Dynanetix, LLC and are protected.

**6: Intellectual Property Rights**

**6.1 Ownership of Intellectual Property**

- **Provider's Intellectual Property Rights:** Provider's "Intellectual Property Rights" encompass all forms of intellectual property owned by Dynanetix, LLC , wherever in the world these rights may exist. This includes both registrable and unregistrable, registered and unregistered rights, such as copyrights and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, utility models, semi-conductor topography rights, and rights in designs.

**6.2 Rights in Service Content**

- **Exclusive Property:** The Service, along with all its original content, features, and functionality, remains the exclusive property of the Provider and its licensors. This content is protected under the copyright laws, trademark laws, and other intellectual property laws of both the State of Delaware, USA, and other foreign jurisdictions.
- **Prohibited Uses:** Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Provider. Unauthorized use of these intellectual property assets is strictly prohibited and may result in legal action.

**6.3 Protection of Intellectual Property**

- **Infringement and Enforcement:** You agree not to infringe upon the intellectual property rights of the Provider or its licensors. This includes, but is not limited to, refraining from copying, modifying, distributing, selling, or leasing any part of the Service, or using our trademarks without prior written permission from the Provider.
- **Reporting Infringements:** If you become aware of any infringement or potential infringement of the Provider's intellectual property rights, you are encouraged to notify us immediately to allow for appropriate action.

**6.4 Usage Limits and Restrictions**

- **Non-commercial Use:** As specified, the Beta Version of the Service is provided solely for testing and feedback purposes and is not intended for commercial use. Accordingly, any commercial use of the Service, including but not limited to business, revenue-generating activities, or other professional uses, is strictly prohibited.
- **Discretionary Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize, which may include restrictions on the number of transactions, amount of data processed, or the number of accesses per time period. These limits are set at the sole discretion of the Provider and are subject to change without prior notice.

**7: Links to Third-Party Websites and Services**

**7.1 Third-Party Links**

- **Presence of Third-Party Links:** Our Service may contain links to third-party websites or services that are not owned or controlled by Dynanetix, LLC . These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s) or their contents.

**7.2 Disclaimer of Control**

- **No Control Over Third-Parties:** Dynanetix, LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge that Dynanetix, LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

**7.3 User Responsibility**

- **Caution Advised:** We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit. You are responsible for reviewing and abiding by the privacy statements and terms of use posted at any third-party sites linked to or from our Service.

**7.4 Limitation of Liability**

- **Indirect Liability:** You further acknowledge and agree that Dynanetix, LLC is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on, or available from, such websites or resources.

**7.5 Recommendations and Endorsements**

- **No Endorsements:** The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Any reliance you place on such information is therefore strictly at your own risk.

**8: Account Cancellation and Suspension**

**8.1 Cancellation and Suspension by Provider**

- **Provider's Rights:** We reserve the right to:
  - (a) Suspend your account;
  - (b) Cancel your account; and/or
  - (c) Edit your account details, at any time in our sole discretion without notice or explanation.
- **Refunds upon Cancellation:** If we cancel any services for which you have paid and you have not breached these terms and conditions, we will refund to you a pro rata amount of your payment. This amount will be calculated by us using a reasonable methodology.

**8.2 Cancellation by User**

- **How to Cancel:** You may cancel your account at any time using the account control panel provided on the Portal.
- **Refund Policy:** You will not be entitled to any refund upon cancellation by you.
- **Effect of Cancellation:** Account cancellation results in the immediate termination of this Agreement. All provisions of this Agreement that, by their nature, should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability, will survive termination.

**8.3 Consequences of Account Cancellation**

- **Data Retention and Deletion:** Upon termination, we may deactivate your account and archive your account information. Certain information may be retained by us where required by law or for legitimate business purposes.
- **Ongoing Obligations:** Termination of this Agreement does not relieve you of any obligations to pay any fees or costs accrued prior to termination and any other amounts owed by you to us as provided in these Terms and Conditions.

**9: Right of Service Refusal**

**9.1 General Right to Refuse Service**

- **Discretion of Provider:** Under these Terms and Conditions, the Provider reserves the right, at its sole discretion, to refuse Service, either partially or fully, to any Organization or person at any time, whether the Service is currently active or not.
- **No Obligation to Explain:** If the decision to refuse Service is made, the Provider is not obligated to provide any argumentation or explanation regarding the decision to refuse Service.

**9.2 Scope of Service Refusal**

- **Partial Refusal:** The Provider may refuse a certain part of the Service, whether it is associated with a particular subscription or not. This includes, but is not limited to, limiting access to certain features, restricting updates, or denying support services.
- **Complete Refusal:** In cases where the Provider refuses to provide Service entirely to a particular Organization or person that has already accepted these Terms, this action may or may not result in the termination of the agreement, as governed by Section 15 of these Terms and Conditions.

**9.3 Consequences of Refusal**

- **Immediate Effect:** Any refusal of Service will take immediate effect upon the decision by the Provider.
- **Modification of Services:** The Provider reserves the right to modify, suspend, or discontinue any aspect of the Service at any time, including the availability of any Service feature, database, or content.
- **Notification:** While the Provider may not provide a detailed explanation for the refusal of Service, affected users or Organizations will be notified of the refusal decision as soon as practicable.

**9: Limitations and Exclusions of Liability**

**9.1 Limitations of Liability**

- **General Limitations:** To the maximum extent permitted by applicable law, the Provider and its suppliers shall not be liable for any special, incidental, indirect, or consequential damages whatsoever. This includes, but is not limited to, damages for:
  - Loss of profits, loss of data or other information,
  - Business interruption,
  - Personal injury,
  - Loss of privacy arising out of or in any way related to the use of or inability to use the Service,
  - Third-party software and/or third-party hardware used with the Service,
  - Any other matter connected with the Service, even if the Provider or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

**9.2 State Law Variations**

- **Variability by Jurisdiction:** Some states/jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations may not apply to you. In these states, each party's liability will be limited to the greatest extent permitted by law.

**9.3 Cap on Liability**

- **Monetary Cap:** The aggregate liability of the Provider to the Customer under this Agreement in respect of any event or series of related events shall not exceed the greater of:
  - $100.00 (one hundred USD); and
  - The total amount paid and payable by the Customer to the Provider under this Agreement in the 3 month period preceding the commencement of the event or events.

**9.4 Personal Liability**

- **Limitation on Personal Claims:** You accept that we have an interest in limiting the personal liability of our officers and employees. You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the Service (this does not limit or exclude the liability of the Provider itself for the acts and omissions of our officers and employees).

**Chapter 10: Indemnity**

**10.1 Indemnification Obligation**

- **Your Commitment to Indemnify:** You hereby indemnify us and undertake to keep us indemnified against any and all losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute) incurred or suffered by us arising directly or indirectly out of your use of our website or any breach by you of any provision of these terms and conditions.

**Chapter 11: Breaches of These Terms and Conditions**

**11.1 Actions for Breach**

- If you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:
  - Send you one or more formal warnings;
  - Temporarily suspend your access to our Portal;
  - Permanently prohibit you from accessing our Portal;
  - Block computers using your IP address from accessing our Portal;
  - Contact any or all of your internet service providers and request that they block your access to our Portal;
  - Commence legal action against you, whether for breach of contract or otherwise; and/or
  - Suspend or delete your account on our website which also may terminate this Agreement in accordance with Section 15.

**11.2 Circumvention Prohibited**

- **Prohibition on Circumvention:** Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).

This integration provides a comprehensive update and augmentation to your existing Terms and Conditions, ensuring clarity on liability, indemnification, and actions against breaches, which are crucial for protecting both the service provider and the users.

12. General Provisions

Severability: If any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in effect.

13. Contact Information

Queries: If you have any questions about these Terms, please contact Dynanetix, LLC at [info@sifu.one](mailto:info@sifu.one)

14: Beta Service

14.1 Acknowledgment of Beta Status

**Beta Version Disclaimer:** You acknowledge that Sifu One is provided as a "Beta Version" and is made available on an "as is" and "as available" basis for the purpose of providing Dynanetix, LLC with feedback on the quality and usability of the service. The Beta Version may contain bugs, errors, and other problems that could cause system or other failures and data loss.

14.2 Free of Charge

**Service Offered at No Cost:** The Beta Version of the Service is provided free of charge. However, it is subject to usage limitations which may include limits on data usage, number of transactions, access times, or other service metrics.

**Usage Limitations:** The specific limits applicable to your use of the Beta Version are determined at the sole discretion of Dynanetix, LLC and may be amended at any time without notice to you. These limitations are put in place to manage strain on resources and ensure stability of the service for all beta testers.

14.3 Commercial Transition

**Potential for Conversion to Commercial Service:** You understand and agree that the Beta Version may be converted into a commercial (paid) service at any time by Dynanetix, LLC . This transition may require you to accept new or additional terms of service and possibly involve different features or capabilities than those available during the beta period.

**Right to Refuse Commercial Service:** Upon conversion of the Beta Service to a commercial offering, you will have the right to discontinue use of the service if you do not wish to transition to the paid version. Your decision to decline the commercial version will not affect your ability to use the beta service up until the point of conversion.

**14.4 No Commercial Use**

**Non-commercial Use:** The Beta Version is provided for testing and feedback purposes only and is not intended for commercial use. As such, it should not be used for any commercial, business, revenue-generating activities, or any other professional use.

**Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize. These limitations may include, but are not limited to, the number of transactions, amount of data processed, or the number of accesses per time period.

**Discretionary Limits:** The specific limits on usage are set at the sole discretion of Dynanetix, LLC and are subject to change at any time without prior consultation with or notification to you. This is necessary to allow for adjustments based on the operational needs and capacities during the testing phase.

**Restriction Against Commercial Use:** Given that the Beta Version is intended for testing and feedback purposes, it is not designed for commercial use. You must not use the Beta Version for any commercial, business, or revenue-generating activities.

**14.5 Discretionary Changes**

**Changes Without Notice:** Dynanetix, LLC reserves the right to modify the usage limitations and operational features of the Beta Version at any time, without prior consultation or notification to you, as deemed necessary for the ongoing management and development of the service.

**15: "AS IS" and "AS AVAILABLE" Disclaimer**

**15.1 Service Disclaimer**

- **No Warranties Provided:** The Service is provided to You "AS IS" and "AS AVAILABLE," with all faults and defects without warranty of any kind. Dynanetix, LLC , on its own behalf and on behalf of its affiliates and licensors, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise from course of dealing, course of performance, usage, or trade practice.

**15.2 Limitations on Warranties**

- **No Guaranteed Performance:** Without limiting the foregoing, neither Dynanetix, LLC nor any of its service providers makes any representation or warranty of any kind, express or implied:
  - (i) as to the operation or availability of the Service, or the information, content, and materials included thereon;
  - (ii) that the Service will be uninterrupted or error-free;
  - (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service;
  - (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of Dynanetix, LLC are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.

**15.3 Applicability of Disclaimers**

- **Jurisdictional Variations:** Some jurisdictions do not allow the exclusion of certain warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. However, in such cases, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

**16: Governing Law, Dispute Resolution**

**16.1 Governing Law**

- **Applicable Law:** These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of New York, USA. Your use of the Service may also be subject to other local, state, national, or international laws.

**16.2 Dispute Resolution**

- **Arbitration Agreement:** Any disputes arising out of or in connection with this Agreement, including any questions regarding its existence, validity, or termination, shall be resolved through arbitration according to the American Arbitration Rules by three arbitrators. The arbitration shall take place in New York City, and the procedural law of this place shall apply where the Rules are silent. The arbitration proceedings shall be conducted in English, and the award shall be final and binding on the parties and enforceable in any court of competent jurisdiction.

**16.3 Waiver of Jury Trial**

- **Jury Trial Waiver:** You and Dynanetix, LLC waive any right to a trial by jury with respect to any dispute or legal action arising from or in connection with these Terms and Conditions.
        `
    : `
**1. Introduction**

- **Acceptance of Terms and Conditions:** These are the Terms and Conditions governing the use of this Service (referred to as "[Sifu One]" or "Portal") and the agreement that operates between the user (referred to as "You" or "Organization") and Dynanetix, LLC (also referred to as "We," "Us," or "Provider"). These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
- **Conditioned Access:** Your access to and use of the Portal and the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions, then You may not access the Service.
- **Representation of Organization:** You fully acknowledge that the Service is intended for organizational use and not for personal use. You represent that when using the Service, you are over the age of 18 and act on behalf of an Organization. The Company does not permit those under 18 to use the Service and prohibits individual use for personal or any other reasons.
- **Binding Agreement:** If you register on the Portal and/or use the Service or expressly agree to these terms and conditions in the course of a business or other organizational project, then by so doing you bind both:
  1. Yourself; and
  2. The Organization to these terms and conditions, and in these circumstances, references to "you" in these terms and conditions are to both the individual user and the relevant person, company, or legal entity (Organization).
- **Privacy Policy Compliance:** Your access to and use of the Portal and the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and informs You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.

**2. Services Description**

- **Service Overview:** Sifu One provides functionality for AI-driven inquiries tailored to users' needs.
- **Service Availability:** We endeavor to provide the best service possible, but you acknowledge that disruptions and technical issues may occur, and Dynanetix, LLC is not liable for any disruption, interruption, or delay in the services.

**3. Registration and Account Integrity**

- **Account Creation by Provider:** Accounts on Sifu One are created by the Provider based on the information provided by you prior to account setup. It is imperative that you provide accurate, complete, and current information as requested by the Provider to facilitate the creation of your account. By submitting your information, you authorize us to create your account on your behalf.
- **Accuracy of Information:** You are responsible for ensuring the accuracy, completeness, and timeliness of the information you provide to us for the purpose of creating and maintaining your account. You must not furnish any information that is misleading, false, or inaccurate. The integrity of your account relies on the truthfulness and precision of the information you provide.
- **Updates to Information:** Should any of your information change post account creation, it is your responsibility to inform us promptly of any such changes to ensure your account information remains accurate. This is crucial to maintaining the security and integrity of your account and the Service overall.
- **Consequences of Inaccurate Information:** Providing false or misleading information, or failing to update information promptly, may result in the suspension or termination of your account and may affect your ability to access and use the Service. Additionally, you may be held liable for any losses incurred by Dynanetix, LLC or other users due to incorrect or outdated information you have provided.

**4 User Conduct and Legal Compliance**

**4.1 Lawful Use of the Service**

- **Requirement for Lawful Use:** You agree to use Sifu One solely for lawful purposes as stipulated by local, state, national, and international laws and regulations. You are responsible for ensuring that your use of the Service complies with all laws applicable to you.

**4.2 Prohibited Activities**

- **Illegal Acts:** You may not use the Service for any purpose that is illegal or prohibited by these Terms. This includes, but is not limited to, engaging in any illegal activities, infringing upon or violating the rights of others, and interfering with or disrupting the security, service integrity, or operations of the Service.

**4.3 Consequences of Unlawful Use**

- **Immediate Termination and Cancellation:** Any use of the Service in violation of any applicable local or international law is strictly prohibited. Such violations will result in immediate termination of your access to the Service and cancellation of your account. We reserve the right to terminate your use of the Service and to take any additional legal action deemed necessary.

**4.4 Reporting Unlawful Activities**

- **Duty to Report:** If you become aware of any illegal activity or breach of these Terms by any user, you are encouraged to inform us immediately. We will investigate all reports and take appropriate action, which may include contacting law enforcement authorities.

**4.5 Cooperation with Law Enforcement**

- **Collaboration with Authorities:** We may disclose any information about you to government or law enforcement officials as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process, to protect the property and rights of Dynanetix, LLC or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, illegal, unethical, or legally actionable activity.

**4.6 Prohibited Uses of the Service**

- **Restriction on Use for Intended Purpose:** The Service is intended solely for its direct purpose as described in these Terms and the accompanying documentation. You are expressly prohibited from using the Service for any of the following purposes:
  - Obtaining business concepts, ideas, methodologies, or strategies;
  - Conducting research or gathering information for any competitive purpose;
  - Reverse engineering, disassembling, or decompiling any part of the Service, or attempting to access the source code;
  - Replicating the functionality or user experience for any competitive endeavor.

**4.7 Protection of Intellectual Property**

- **Prohibition Against Intellectual Property Infringement:** Using the Service to infringe upon or misappropriate our intellectual property or that of others, including attempting to discover trade secrets or confidential information through the use of the Service, is strictly prohibited. This includes using the Service to develop competing products or services.

**4.8 Enforcement and Penalties**

- **Immediate Consequences for Prohibited Uses:** Any use of the Service that violates these restrictions is subject to immediate termination of your account and legal action. We reserve the right to enforce these terms through civil litigation and cooperation with law enforcement authorities as necessary.
- **Notification of Violations:** If you suspect any misuse of the Service or any act of reverse engineering or competitive abuse, you are required to report it immediately to [Provider's Contact Information].

**4.9 Security Monitoring**

- **Ongoing Monitoring for Compliance:** We may monitor the use of the Service to ensure compliance with these Terms. Any detection of prohibited activities may lead to investigation, suspension of the account, and possible legal actions to protect our intellectual property and business interests.

**5: User-Generated Content**

**5.1 User Content Warranty**

- **Compliance Warranty:** You warrant and represent that your content will comply with these terms and conditions. You are solely responsible for ensuring that your content is of sufficient quality and in an appropriate format for its intended use.

**5.2 Prohibitions on User Content**

- **Lawfulness and Rights Infringement:** Your content must not be illegal or unlawful, must not infringe any person's legal rights, and must not be capable of giving rise to legal action against any person (in each case in any jurisdiction and under any applicable law).
- **Content Restrictions:** Your content, and the use of your content by us in accordance with these terms and conditions, must not:
  - Be libellous, maliciously false, obscene, indecent, or infringe any intellectual property right;
  - Violate any right of confidence, privacy, or data protection legislation;
  - Constitute negligent advice, incite crime, be in contempt of court, or breach court orders;
  - Breach racial or religious hatred or discrimination legislation;
  - Be blasphemous, in breach of official secrets legislation, or any contractual obligations;
  - Depict violence explicitly, be pornographic, lewd, suggestive, or sexually explicit;
  - Be untrue, false, inaccurate, misleading, constitute spam, or be offensive, deceptive, fraudulent, threatening, abusive, harassing, anti-social, menacing, hateful, discriminatory, inflammatory;
  - Cause annoyance, inconvenience, or needless anxiety.

**5.3 Liability for User Content**

- **Responsibility and Liability:** You acknowledge that you are solely responsible for any content you supply to us. We will not be liable for any loss, damage, or legal consequences that arise from your content or its use in our Service. This includes any claims related to the accuracy, legality, or any other aspect of your content.
- **No Liability in Customised Products:** Subject to provisions detailed in Section 15.1 of these terms, we will not be liable for any expense, loss, or damage arising out of your content or the incorporation of your content into our products, regardless of whether the content has been transformed or used verbatim.

**5.4 Right to Remove or Modify Content**

- **Content Management:** We reserve the right to edit, remove, or refuse any content provided by users at our sole discretion if we believe it violates these terms, applicable laws, or is otherwise objectionable. We are not obliged to retain or provide you with copies of your content.

**5.5 Third-Party Content Permissions**

- **Consent Requirement:** If your content includes material obtained from third parties, whether individuals or organizations, you must secure full consent from such third parties to share this content with us. You must ensure that this consent covers the unrestricted use of the content by Dynanetix, LLC without any limitations.
- **Warranty of Permission:** By submitting third-party content to our Service, you warrant and represent that you have obtained the necessary permissions, rights, and licenses to use and share the content. This includes the right to grant us the same level of access and usage.
- **Indemnification for Third-Party Claims:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary third-party permissions. This indemnification also covers any misuse or unlawful use of third-party content provided by you.

**5.6 Responsibility for Ensuring Consent**

- **Duty to Verify and Document Consent:** You are responsible for verifying and maintaining records of all consents and permissions related to third-party content. You should be prepared to provide evidence of these permissions to Dynanetix, LLC upon our request to verify compliance with these terms.

**5.7 Handling of Private and Sensitive Information**

- **Consent for Private Information:** If the content obtained from third parties includes private or sensitive information, such as personal names, addresses, emails, phone numbers, etc., you must ensure that such information is obtained with the full discretion and explicit consent of the third party.
- **Informed Third-Party Consent:** Before sharing any personal or sensitive information with Dynanetix, LLC , you must confirm that the third party is fully aware that this information will be shared with us and has explicitly agreed to this sharing. This consent should be documented and should specify the extent to which the information can be used and disclosed by Dynanetix, LLC .
- **Duty to Protect Privacy:** It is your responsibility to protect the privacy rights of third parties by ensuring that the information is collected and shared in compliance with applicable data protection laws. This includes implementing appropriate security measures to protect the data from unauthorized access or breaches.

**5.8 Liability for Breach of Privacy**

- **Indemnification for Privacy Violations:** You agree to indemnify and hold harmless Dynanetix, LLC from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising from or in any way related to your failure to obtain the necessary consents for private or sensitive information. This includes any violations of data protection laws resulting from your actions.

**5.9 Compliance with Data Protection Regulations**

- **Adherence to Laws:** You must ensure that all activities related to the gathering, storage, and sharing of personal and sensitive information adhere to the highest standards of data protection as dictated by relevant local and international laws.

**6. Intellectual Property**

- **Service Content, Software and Trademarks:** You acknowledge that all intellectual property rights in the service and its original content, features, and functionality are owned by Dynanetix, LLC and are protected.

**6: Intellectual Property Rights**

**6.1 Ownership of Intellectual Property**

- **Provider's Intellectual Property Rights:** Provider's "Intellectual Property Rights" encompass all forms of intellectual property owned by Dynanetix, LLC , wherever in the world these rights may exist. This includes both registrable and unregistrable, registered and unregistered rights, such as copyrights and related rights, database rights, confidential information, trade secrets, know-how, business names, trade names, trademarks, service marks, passing off rights, unfair competition rights, patents, utility models, semi-conductor topography rights, and rights in designs.

**6.2 Rights in Service Content**

- **Exclusive Property:** The Service, along with all its original content, features, and functionality, remains the exclusive property of the Provider and its licensors. This content is protected under the copyright laws, trademark laws, and other intellectual property laws of both the State of Delaware, USA, and other foreign jurisdictions.
- **Prohibited Uses:** Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Provider. Unauthorized use of these intellectual property assets is strictly prohibited and may result in legal action.

**6.3 Protection of Intellectual Property**

- **Infringement and Enforcement:** You agree not to infringe upon the intellectual property rights of the Provider or its licensors. This includes, but is not limited to, refraining from copying, modifying, distributing, selling, or leasing any part of the Service, or using our trademarks without prior written permission from the Provider.
- **Reporting Infringements:** If you become aware of any infringement or potential infringement of the Provider's intellectual property rights, you are encouraged to notify us immediately to allow for appropriate action.

**6.4 Usage Limits and Restrictions**

- **Non-commercial Use:** As specified, the Beta Version of the Service is provided solely for testing and feedback purposes and is not intended for commercial use. Accordingly, any commercial use of the Service, including but not limited to business, revenue-generating activities, or other professional uses, is strictly prohibited.
- **Discretionary Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize, which may include restrictions on the number of transactions, amount of data processed, or the number of accesses per time period. These limits are set at the sole discretion of the Provider and are subject to change without prior notice.

**7: Links to Third-Party Websites and Services**

**7.1 Third-Party Links**

- **Presence of Third-Party Links:** Our Service may contain links to third-party websites or services that are not owned or controlled by Dynanetix, LLC . These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s) or their contents.

**7.2 Disclaimer of Control**

- **No Control Over Third-Parties:** Dynanetix, LLC has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge that Dynanetix, LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.

**7.3 User Responsibility**

- **Caution Advised:** We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit. You are responsible for reviewing and abiding by the privacy statements and terms of use posted at any third-party sites linked to or from our Service.

**7.4 Limitation of Liability**

- **Indirect Liability:** You further acknowledge and agree that Dynanetix, LLC is not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy, or existence of any advertising, products, or other materials on, or available from, such websites or resources.

**7.5 Recommendations and Endorsements**

- **No Endorsements:** The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them. Any reliance you place on such information is therefore strictly at your own risk.

**8: Account Cancellation and Suspension**

**8.1 Cancellation and Suspension by Provider**

- **Provider's Rights:** We reserve the right to:
  - (a) Suspend your account;
  - (b) Cancel your account; and/or
  - (c) Edit your account details, at any time in our sole discretion without notice or explanation.
- **Refunds upon Cancellation:** If we cancel any services for which you have paid and you have not breached these terms and conditions, we will refund to you a pro rata amount of your payment. This amount will be calculated by us using a reasonable methodology.

**8.2 Cancellation by User**

- **How to Cancel:** You may cancel your account at any time using the account control panel provided on the Portal.
- **Refund Policy:** You will not be entitled to any refund upon cancellation by you.
- **Effect of Cancellation:** Account cancellation results in the immediate termination of this Agreement. All provisions of this Agreement that, by their nature, should survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability, will survive termination.

**8.3 Consequences of Account Cancellation**

- **Data Retention and Deletion:** Upon termination, we may deactivate your account and archive your account information. Certain information may be retained by us where required by law or for legitimate business purposes.
- **Ongoing Obligations:** Termination of this Agreement does not relieve you of any obligations to pay any fees or costs accrued prior to termination and any other amounts owed by you to us as provided in these Terms and Conditions.

**9: Right of Service Refusal**

**9.1 General Right to Refuse Service**

- **Discretion of Provider:** Under these Terms and Conditions, the Provider reserves the right, at its sole discretion, to refuse Service, either partially or fully, to any Organization or person at any time, whether the Service is currently active or not.
- **No Obligation to Explain:** If the decision to refuse Service is made, the Provider is not obligated to provide any argumentation or explanation regarding the decision to refuse Service.

**9.2 Scope of Service Refusal**

- **Partial Refusal:** The Provider may refuse a certain part of the Service, whether it is associated with a particular subscription or not. This includes, but is not limited to, limiting access to certain features, restricting updates, or denying support services.
- **Complete Refusal:** In cases where the Provider refuses to provide Service entirely to a particular Organization that has already accepted these Terms, this action may or may not result in the termination of the agreement, as governed by Section 15 of these Terms and Conditions.

**9.3 Consequences of Refusal**

- **Immediate Effect:** Any refusal of Service will take immediate effect upon the decision by the Provider.
- **Modification of Services:** The Provider reserves the right to modify, suspend, or discontinue any aspect of the Service at any time, including the availability of any Service feature, database, or content.
- **Notification:** While the Provider may not provide a detailed explanation for the refusal of Service, affected users or Organizations will be notified of the refusal decision as soon as practicable.

**9: Limitations and Exclusions of Liability**

**9.1 Limitations of Liability**

- **General Limitations:** To the maximum extent permitted by applicable law, the Provider and its suppliers shall not be liable for any special, incidental, indirect, or consequential damages whatsoever. This includes, but is not limited to, damages for:
  - Loss of profits, loss of data or other information,
  - Business interruption,
  - Personal injury,
  - Loss of privacy arising out of or in any way related to the use of or inability to use the Service,
  - Third-party software and/or third-party hardware used with the Service,
  - Any other matter connected with the Service, even if the Provider or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.

**9.2 State Law Variations**

- **Variability by Jurisdiction:** Some states/jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the above limitations may not apply to you. In these states, each party's liability will be limited to the greatest extent permitted by law.

**9.3 Cap on Liability**

- **Monetary Cap:** The aggregate liability of the Provider to the Customer under this Agreement in respect of any event or series of related events shall not exceed the greater of:
  - $100.00 (one hundred USD); and
  - The total amount paid and payable by the Customer to the Provider under this Agreement in the 3 month period preceding the commencement of the event or events.

**9.4 Personal Liability**

- **Limitation on Personal Claims:** You accept that we have an interest in limiting the personal liability of our officers and employees. You agree that you will not bring any claim personally against our officers or employees in respect of any losses you suffer in connection with the Service (this does not limit or exclude the liability of the Provider itself for the acts and omissions of our officers and employees).

**10: Indemnity**

**10.1 Indemnification Obligation**

- **Your Commitment to Indemnify:** You hereby indemnify us and undertake to keep us indemnified against any and all losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by us to a third party in settlement of a claim or dispute) incurred or suffered by us arising directly or indirectly out of your use of our website or any breach by you of any provision of these terms and conditions.

**11: Breaches of These Terms and Conditions**

**11.1 Actions for Breach**

- If you breach these terms and conditions in any way, or if we reasonably suspect that you have breached these terms and conditions in any way, we may:
  - Send you one or more formal warnings;
  - Temporarily suspend your access to our Portal;
  - Permanently prohibit you from accessing our Portal;
  - Block computers using your IP address from accessing our Portal;
  - Contact any or all of your internet service providers and request that they block your access to our Portal;
  - Commence legal action against you, whether for breach of contract or otherwise; and/or
  - Suspend or delete your account on our website which also may terminate this Agreement in accordance with Section 15.

**11.2 Circumvention Prohibited**

- **Prohibition on Circumvention:** Where we suspend or prohibit or block your access to our website or a part of our website, you must not take any action to circumvent such suspension or prohibition or blocking (including without limitation creating and/or using a different account).

This integration provides a comprehensive update and augmentation to your existing Terms and Conditions, ensuring clarity on liability, indemnification, and actions against breaches, which are crucial for protecting both the service provider and the users.

**12: General Provisions**

Severability: If any provision of these Terms is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the remaining provisions of these Terms will remain in effect.

**13: Contact Information**

Queries: If you have any questions about these Terms, please contact Dynanetix, LLC by [info@sifu.one](mailto:info@sifu.one) .

**14: Beta Service**

**14.1 Acknowledgment of Beta Status**

**Beta Version Disclaimer:** You acknowledge that Sifu One is provided as a "Beta Version" and is made available on an "as is" and "as available" basis for the purpose of providing Dynanetix, LLC with feedback on the quality and usability of the service. The Beta Version may contain bugs, errors, and other problems that could cause system or other failures and data loss.

**14.2 Free of Charge**

**Service Offered at No Cost:** The Beta Version of the Service is provided free of charge. However, it is subject to usage limitations which may include limits on data usage, number of transactions, access times, or other service metrics.

**Usage Limitations:** The specific limits applicable to your use of the Beta Version are determined at the sole discretion of Dynanetix, LLC and may be amended at any time without notice to you. These limitations are put in place to manage strain on resources and ensure stability of the service for all beta testers.

**14.3 Commercial Transition**

**Potential for Conversion to Commercial Service:** You understand and agree that the Beta Version may be converted into a commercial (paid) service at any time by Dynanetix, LLC . This transition may require you to accept new or additional terms of service and possibly involve different features or capabilities than those available during the beta period.

**Right to Refuse Commercial Service:** Upon conversion of the Beta Service to a commercial offering, you will have the right to discontinue use of the service if you do not wish to transition to the paid version. Your decision to decline the commercial version will not affect your ability to use the beta service up until the point of conversion.

**14.4 No Commercial Use**

**Non-commercial Use:** The Beta Version is provided for testing and feedback purposes only and is not intended for commercial use. As such, it should not be used for any commercial, business, revenue-generating activities, or any other professional use.

**Usage Limits:** Due to the nature of the Beta Version, there are limitations on the amount of services you can utilize. These limitations may include, but are not limited to, the number of transactions, amount of data processed, or the number of accesses per time period.

**Discretionary Limits:** The specific limits on usage are set at the sole discretion of Dynanetix, LLC and are subject to change at any time without prior consultation with or notification to you. This is necessary to allow for adjustments based on the operational needs and capacities during the testing phase.

**Restriction Against Commercial Use:** Given that the Beta Version is intended for testing and feedback purposes, it is not designed for commercial use. You must not use the Beta Version for any commercial, business, or revenue-generating activities.

**14.5 Discretionary Changes**

**Changes Without Notice:** Dynanetix, LLC reserves the right to modify the usage limitations and operational features of the Beta Version at any time, without prior consultation or notification to you, as deemed necessary for the ongoing management and development of the service.

**15: "AS IS" and "AS AVAILABLE" Disclaimer**

**15.1 Service Disclaimer**

- **No Warranties Provided:** The Service is provided to You "AS IS" and "AS AVAILABLE," with all faults and defects without warranty of any kind. Dynanetix, LLC, on its own behalf and on behalf of its affiliates and licensors, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise from course of dealing, course of performance, usage, or trade practice.

**15.2 Limitations on Warranties**

- **No Guaranteed Performance:** Without limiting the foregoing, neither Dynanetix, LLC nor any of its service providers makes any representation or warranty of any kind, express or implied:
  - (i) as to the operation or availability of the Service, or the information, content, and materials included thereon;
  - (ii) that the Service will be uninterrupted or error-free;
  - (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service;
  - (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of Dynanetix, LLC are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.

**15.3 Applicability of Disclaimers**

- **Jurisdictional Variations:** Some jurisdictions do not allow the exclusion of certain warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to you. However, in such cases, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.

**16: Governing Law, Dispute Resolution**

**16.1 Governing Law**

- **Applicable Law:** These Terms and Conditions shall be governed by and construed in accordance with the laws of the State of New York, USA. Your use of the Service may also be subject to other local, state, national, or international laws.

**16.2 Dispute Resolution**

- **Arbitration Agreement:** Any disputes arising out of or in connection with this Agreement, including any questions regarding its existence, validity, or termination, shall be resolved through arbitration according to the American Arbitration Rules by three arbitrators. The arbitration shall take place in New York City, and the procedural law of this place shall apply where the Rules are silent. The arbitration proceedings shall be conducted in English, and the award shall be final and binding on the parties and enforceable in any court of competent jurisdiction.

**16.3 Waiver of Jury Trial**

- **Jury Trial Waiver:** Both the Organization and Dynanetix, LLC waive any right to a trial by jury with respect to any dispute or legal action arising from or in connection with these Terms and Conditions.
        `,
});

const relations: Record<string, keyof ReturnType<typeof texts>> = {
  localhost: 'prescreen',
  prescreenai: 'prescreen',
  sifu: 'sifu',
};

const getData = (host: string, isIndividual: boolean) =>
  texts(isIndividual)[
    Object.entries(relations).find(([key]) =>
      host.toLowerCase().includes(key.toLowerCase())
    )?.[1] as keyof ReturnType<typeof texts>
  ];

export default getData;
