import { IBot } from 'store/bots';

const DEFAULT_HOSTS = [
  {
    hosts: ['ai-chat-admin-fe.dev.processica.com', 'localhost'],
    url: 'https://ai-chat-ui.dev.processica.com',
  },
  {
    hosts: ['ai-chat-admin-fe.apps.processica.com'],
    url: 'https://ai-chat-ui.apps.processica.com',
  },
];

export const getInvitationHost = (bot: IBot) => {
  let host =
    bot.host ||
    DEFAULT_HOSTS.find(({ hosts }) => hosts.includes(location.hostname))?.url ||
    '';
  if (!host.startsWith('http')) {
    host = 'https://' + host;
  }
  return host.replace(/\/+$/, '') + '/';
};

export const makeInvitationLink = (bot: IBot, hash: string) =>
  `${getInvitationHost(bot)}?invitation=${hash}`;

