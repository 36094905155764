import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Steps,
  Tag,
} from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { getLabels } from 'labels';
import { copyToClipboard, validateEmail } from 'utils/data';
import { alertSuccess, alertWarning } from 'utils/alert';
import { fetchBotLanguages, invite, sendInvitationEmail } from 'api/bots';
import {
  fetchHRInfo,
  fetchHRJobPositions,
  sendHrInfo,
  sendHRJobPosition,
} from 'api/hr';
import bots, { IBot, IBotLanguage } from 'store/bots';
import { IHRInfo, IHRPosition, subscribe } from 'store/users';

import Upload from 'components/upload';

import { getInvitationHost, makeInvitationLink } from './utils/invitation';
import Text, { TTextRef } from './text';

import styles from '../styles.module.scss';

export type TInvitationProps = { bots: IBot[] };
export type TFileToText = { name: string; content: string };

type TStepType = 'data' | 'job' | 'link';
type TStep = {
  type: TStepType;
  title: string;
  hr?: boolean;
};
const steps: TStep[] = [
  {
    type: 'data',
    title: `${getLabels(location.href).user} data`,
  },
  {
    type: 'job',
    title: 'Job Position',
    hr: true,
  },
  {
    type: 'link',
    title: 'Link',
  },
];

const invitation: {
  open?: () => void;
} = {};

export const useInvitation = () => invitation;

const Invitation: React.FC<TInvitationProps> = props => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [langs, setLangs] = useState<IBotLanguage[]>([]);
  const [botId, setBotId] = useState<string>();
  const [langCode, setLangCode] = useState<string>('');
  const [langStrict, setLangStrict] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [email, setEmail] = useState<string>();
  const [step, setStep] = useState<TStepType>('data');
  const [hash, setHash] = useState<string>('');

  useEffect(() => {
    invitation.open = () => {
      setBotId(props.bots[0]?.id || '');
      setEmail('');
      resetData();
      setOpened(true);
      load();
    };
    return () => (invitation.open = undefined);
  }, [props.bots]);

  const botsOptions: DefaultOptionType[] = props.bots.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  const langsOptions: DefaultOptionType[] = langs.map(
    ({ code, language_name }) => ({
      value: code,
      label: language_name,
    })
  );

  const preparedEmail = email?.trim();
  const isValidEmail = !!preparedEmail?.length && validateEmail(preparedEmail);

  const [generating, setGenerating] = useState<boolean>(false);

  const isHRBot = !!props.bots.find(({ id }) => id === botId)?.extra?.hr;
  const [hrPositions, setHrPositions] = useState<IHRPosition[] | null>(null);
  const [hrPositionId, setHrPositionId] = useState<string | null>(null);
  const [hrAddingPosition, setHrAddingPosition] = useState<boolean>(false);
  const [hrInfo, setHrInfo] = useState<IHRInfo | null>(null);
  const [hrSending, setHrSending] = useState<boolean>(false);
  const [hrCVData, setHrCVData] = useState<string>('');
  const [hrCVFileName, setHrCVFileName] = useState<string>('');
  const [hrJobFileName, setHrJobFileName] = useState<string>('');

  const resetData = (skipMain?: boolean) => {
    setStep('data');
    setHash('');
    setUserId('');
    setGenerating(false);
    setSendingEmail(false);
    setHrPositions(null);
    setHrPositionId(null);
    setHrInfo(null);
    setHrSending(false);
    setHrPositionName('');
    setHrPositionConfirmVisible(false);
    setHrJobFileName('');
    if (!skipMain) {
      setLangStrict(false);
      setHrCVData('');
      setHrCVFileName('');
    }
  };

  useEffect(() => resetData(true), [botId, email]);

  const load = async () => {
    try {
      setLoading(true);
      const result = await fetchBotLanguages();
      setLangs(result);
      setLangCode(
        result.some(({ code }) => code === 'en') ? 'en' : result[0]?.code
      );
    } finally {
      setLoading(false);
    }
  };

  const hrNextPosition = useRef<string>('');
  const [hrPositionConfirmVisible, setHrPositionConfirmVisible] =
    useState<boolean>(false);
  const updateHrPositionId = () => {
    setHrPositionId(hrNextPosition.current);
    hrNextPosition.current = '';
    setHrPositionConfirmVisible(false);
  };
  const handleHrSelectPosition = (positionId: string) => {
    hrNextPosition.current = positionId;
    const position = hrPositions?.find(({ id }) => id === positionId);
    if (!position) return;
    if (
      (!!hrInfo?.job_description &&
        hrInfo?.job_description !== position.job_description) ||
      (!!hrInfo?.success_criteria &&
        hrInfo?.success_criteria !== position.success_criteria) ||
      (!!hrInfo?.questions_to_ask &&
        hrInfo?.questions_to_ask !== position.questions_to_ask)
    ) {
      setHrPositionConfirmVisible(true);
    } else {
      updateHrPositionId();
    }
  };
  useEffect(() => {
    if (!botId || !userId || !hrPositionId) return;
    const position = hrPositions?.find(({ id }) => id === hrPositionId);
    setHrInfo(value => ({
      user_id: userId,
      bot_id: botId,
      ...value,
      job_position_id: hrPositionId,
      job_description: position?.job_description,
      success_criteria: position?.success_criteria,
      questions_to_ask: position?.questions_to_ask,
    }));
  }, [userId, botId, hrPositionId]);

  const handleClose = () => setOpened(false);

  const updateHRInfo = async (customUserId?: string) => {
    if (!botId) return;
    const result = await fetchHRInfo(customUserId || userId, botId);
    setHrInfo(result || { user_id: customUserId || userId, bot_id: botId });
    setHrPositionId(result?.job_position_id || null);
  };

  const sendData = async () => {
    if (!botId || !isValidEmail) return;
    const preparedEmail = email?.trim() || '';
    try {
      setGenerating(true);
      const data = await invite(botId, preparedEmail, langCode, langStrict);
      if (data) {
        setHash(data.hash);
        setUserId(data.user.id);
        if (isHRBot) {
          if (!hrPositions) {
            const positions = await fetchHRJobPositions();
            setHrPositions(positions);
            setHrAddingPosition(!positions.length);
          }
          await subscribe(data.user.id, [
            ...(data.user.subscriptions || []).filter(({ id }) => id !== botId),
            {
              id: botId,
              settings: {
                language: langCode,
                language_policy_strict: langStrict,
              },
            },
          ]);
          await updateHRInfo(data.user.id);
          setStep('job');
        } else {
          setStep('link');
        }
      }
    } finally {
      setGenerating(false);
    }
  };

  const [hrPositionName, setHrPositionName] = useState<string>('');
  const hrAddPosition = async () => {
    const name = hrPositionName.trim();
    if (!name || !hrInfo?.job_description) return false;
    if (
      hrPositions?.find(
        position => position.name.toLowerCase().trim() === name.toLowerCase()
      )
    ) {
      alertWarning('Job Position with the same name exists');
      return false;
    }
    try {
      const position = await sendHRJobPosition(
        name,
        hrInfo?.job_description,
        hrInfo?.success_criteria || null,
        hrInfo?.questions_to_ask || null
      );
      if (position) {
        const positions = await fetchHRJobPositions();
        setHrPositionId(position?.id);
        setHrPositions(positions);
        setHrPositionName('');
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const hrSend = async () => {
    if (!botId || !userId || !hrInfo) return;
    try {
      setHrSending(true);
      let needSend = true;
      if (hrAddingPosition) {
        needSend = await hrAddPosition();
      }
      if (needSend) {
        const info = await sendHrInfo({
          ...hrInfo,
          cv: hrCVData,
        });
        setHrInfo(info);
        setStep('link');
      }
    } finally {
      setHrSending(false);
    }
  };

  const textRef = useRef<TTextRef>(null);

  const bot = bots.list.find(({ id }) => id === botId);

  const fullUrl = !!bot ? makeInvitationLink(bot, hash) : '';

  const copyLink = async () => {
    copyToClipboard(fullUrl).then(
      result => result && alertSuccess('Invitation link was copied')
    );
  };

  const [sendingEmail, setSendingEmail] = useState<boolean>(false);
  const handleSendEmail = async () => {
    try {
      setSendingEmail(true);
      const result =
        bot &&
        (await sendInvitationEmail(userId, bot.id, getInvitationHost(bot)));
      !!result && alertSuccess('Invitation link was sent');
    } finally {
      setSendingEmail(false);
    }
  };

  const hrPositionsOptions: DefaultOptionType[] =
    hrPositions?.map(({ id, name }) => ({ value: id, label: name })) || [];

  const filteredSteps = steps.filter(({ hr }) => !hr || (isHRBot && hr));
  const currentStep = filteredSteps.findIndex(({ type }) => type === step);
  const stepsTitles = filteredSteps.map(({ title }) => ({ title }));

  const handleBackClick = () => setStep(filteredSteps[currentStep - 1].type);

  const handleNextClick = async () => {
    switch (step) {
      case 'data':
        return sendData();
      case 'job':
        return hrSend();
      case 'link':
        return copyLink();
    }
  };

  let nextTitle = 'Next';
  switch (step) {
    case 'job':
      nextTitle = hrAddingPosition ? 'Save and Next' : 'Next';
      break;
    case 'link':
      nextTitle = 'Copy invitation';
      break;
  }

  let isNextLoading = false;
  switch (step) {
    case 'data':
      isNextLoading = generating;
      break;
    case 'job':
      isNextLoading = hrSending;
      break;
  }

  let isNextDisabled = false;
  switch (step) {
    case 'data':
      isNextDisabled = !botId || !isValidEmail || loading;
      break;
    case 'job':
      if (hrAddingPosition) {
        isNextDisabled = !hrPositionName.trim() || !hrInfo?.job_description;
      } else {
        isNextDisabled = !hrInfo?.job_description || !hrPositionId;
      }
      break;
  }

  const isBackDisabled = hrSending || sendingEmail;

  return (
    <>
      <Modal
        title="Invitation"
        okText={nextTitle}
        cancelText="Close"
        maskClosable={false}
        open={opened}
        width={800}
        okButtonProps={{ disabled: isNextDisabled }}
        onOk={handleNextClick}
        onCancel={handleClose}
        confirmLoading={isNextLoading}
        footer={(_, extra) => (
          <Space className={styles.invitationFooter}>
            <extra.CancelBtn />
            {step !== 'data' && (
              <Button disabled={isBackDisabled} onClick={handleBackClick}>
                Back
              </Button>
            )}
            <Space.Compact>
              <extra.OkBtn />
              {step === 'link' && (
                <Button
                  type="primary"
                  loading={sendingEmail}
                  onClick={handleSendEmail}
                >
                  Send email
                </Button>
              )}
            </Space.Compact>
          </Space>
        )}
      >
        <div className={styles.invitation}>
          <Steps
            size="small"
            current={currentStep}
            items={stepsTitles}
            className={styles.steps}
          />
          {step === 'data' && (
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Flex align="stretch" vertical>
                  <Select
                    disabled={props.bots.length === 1 || generating}
                    placeholder="Select bot"
                    options={botsOptions}
                    value={botId}
                    onChange={setBotId}
                  />
                </Flex>
              </Col>
              <Col span={24}>
                <Input
                  placeholder="Email"
                  value={email}
                  disabled={generating}
                  onChange={({ currentTarget }) =>
                    setEmail(currentTarget.value)
                  }
                />
              </Col>
              <Col span={24}>
                <Flex align="stretch" vertical>
                  <Select
                    disabled={loading || langs.length < 2 || generating}
                    placeholder="Select language"
                    options={langsOptions}
                    loading={loading}
                    value={langCode}
                    onChange={setLangCode}
                  />
                </Flex>
              </Col>
              <Col span={24}>
                <Checkbox
                  checked={langStrict}
                  onChange={({ target }) => setLangStrict(target.checked)}
                >
                  Language policy should be strict
                </Checkbox>
              </Col>
              {isHRBot && (
                <Col span={24}>
                  <Space.Compact block>
                    <Upload<TFileToText>
                      className={styles.upload}
                      title={
                        <Flex vertical align="flex-start">
                          <span>Upload CV</span>
                          {!!hrCVFileName && (
                            <Tag
                              color="default"
                              className={styles.uploadFileName}
                            >
                              {hrCVFileName}
                            </Tag>
                          )}
                        </Flex>
                      }
                      disabled={generating}
                      accept="application/pdf"
                      url="file_to_text"
                      field="cv"
                      filled={!!hrCVData}
                      onUpload={({ content, name }) => {
                        setHrCVData(content);
                        setHrCVFileName(name);
                      }}
                    />
                    <Button
                      disabled={!hrCVData}
                      onClick={() => textRef.current?.open('CV', hrCVData)}
                    >
                      Show
                    </Button>
                  </Space.Compact>
                </Col>
              )}
            </Row>
          )}

          {step === 'job' && (
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Form layout="vertical">
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      {hrAddingPosition ? (
                        <Form.Item label="New Job Position name" required>
                          <Space.Compact block>
                            <Input
                              placeholder="Enter unique name"
                              value={hrPositionName}
                              onChange={({ currentTarget }) =>
                                setHrPositionName(currentTarget.value)
                              }
                              disabled={hrSending}
                            />
                            {!!hrPositions?.length && (
                              <Button
                                disabled={hrSending}
                                onClick={() => setHrAddingPosition(false)}
                              >
                                Select existing
                              </Button>
                            )}
                          </Space.Compact>
                        </Form.Item>
                      ) : (
                        <Form.Item label="Job Position Templates">
                          <Space.Compact block>
                            <Popconfirm
                              title="Replace Jobs Position fields data"
                              description="Selecting another Job Position will replace unsaved data"
                              open={hrPositionConfirmVisible}
                              okText="Select"
                              cancelText="Cancel"
                              onConfirm={updateHrPositionId}
                              onCancel={() =>
                                setHrPositionConfirmVisible(false)
                              }
                            >
                              <Select
                                placeholder="Select a preset Job Position"
                                options={hrPositionsOptions}
                                value={hrPositionId}
                                disabled={hrSending}
                                onChange={handleHrSelectPosition}
                              />
                            </Popconfirm>
                            <Button
                              disabled={hrSending}
                              onClick={() => setHrAddingPosition(true)}
                            >
                              Add new
                            </Button>
                          </Space.Compact>
                        </Form.Item>
                      )}
                    </Col>
                    <Col span={24}>
                      <Space.Compact block>
                        <Upload<TFileToText>
                          className={styles.upload}
                          title={
                            <Flex vertical align="flex-start">
                              <span>Upload Job Requirements</span>
                              {!!hrJobFileName && (
                                <Tag
                                  color="default"
                                  className={styles.uploadFileName}
                                >
                                  {hrJobFileName}
                                </Tag>
                              )}
                            </Flex>
                          }
                          accept="application/pdf"
                          url="file_to_text"
                          filled={!!hrInfo?.job_description}
                          disabled={hrSending}
                          required
                          onUpload={({ content, name }) => {
                            setHrInfo({
                              user_id: userId,
                              bot_id: botId || '',
                              ...hrInfo,
                              job_description: content,
                            });
                            setHrJobFileName(name);
                          }}
                        />
                        <Button
                          disabled={!hrInfo?.job_description}
                          onClick={() =>
                            textRef.current?.open(
                              'CV',
                              hrInfo?.job_description || ''
                            )
                          }
                        >
                          Show
                        </Button>
                      </Space.Compact>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Special criteria">
                        <Input.TextArea
                          rows={5}
                          placeholder="Enter Special criteria or leave this field blank"
                          value={hrInfo?.success_criteria}
                          disabled={hrSending}
                          onChange={({ currentTarget }) =>
                            setHrInfo(data => ({
                              ...(data || {
                                user_id: userId,
                                bot_id: botId || '',
                              }),
                              success_criteria: currentTarget.value,
                            }))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="Additional questions">
                        <Input.TextArea
                          rows={5}
                          placeholder="Enter Additional questions or leave this field blank"
                          value={hrInfo?.questions_to_ask}
                          disabled={hrSending}
                          onChange={({ currentTarget }) =>
                            setHrInfo(data => ({
                              ...(data || {
                                user_id: userId,
                                bot_id: botId || '',
                              }),
                              questions_to_ask: currentTarget.value,
                            }))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}

          {step === 'link' && (
            <Input.TextArea value={fullUrl} rows={10} disabled />
          )}
        </div>
      </Modal>
      <Text ref={textRef} />
    </>
  );
};

export default Invitation;
