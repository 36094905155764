/* eslint-disable react/jsx-props-no-spreading,react/no-unstable-nested-components */
import React, {
  FormEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  Badge,
  Button,
  Drawer,
  Input,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { frontDate } from 'utils/format';
import profile from 'store/profile';
import bots from 'store/bots';
import users, {
  loadUsers,
  IUser,
  loadFlags,
  TUserStatus,
  changeUserStatus,
  IUserSubscription,
} from 'store/users';

import { usePassword } from 'modals/password';

import Creation, { TCreationRef } from './modals/creation';
import UserForm from './modals/form';
import Invitation, { useInvitation } from './modals/invitation';

import styles from './styles.module.scss';

const getColumns = (params: {
  profileId: string;
  inviteEnabled: boolean;
  isAdmin: boolean;
  bots: Record<string, string>;
  onCreate?: () => void;
  onInvite?: () => void;
  onOpen: (id: string) => void;
  onChangeStatus: (id: string) => void;
  statusing: string[];
  flags: string[];
}): ColumnsType<IUser> => [
  {
    title: 'Email',
    dataIndex: 'email',
    ellipsis: true,
    render: (value, { id }: IUser) => (
      <>
        <Typography.Text strong={id === params.profileId}>
          {value}
        </Typography.Text>
        {params.flags.includes(id) && <Badge dot />}
      </>
    ),
    sortDirections: ['descend'],
    sorter: (a, b) => a.email.localeCompare(b.email),
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Full name',
    dataIndex: 'full_name',
    ellipsis: true,
    responsive: ['xl'],
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) =>
      (
        a.full_name || String.fromCharCode(Number.MAX_SAFE_INTEGER)
      ).localeCompare(
        b.full_name || String.fromCharCode(Number.MAX_SAFE_INTEGER)
      ),
  },
  {
    title: 'Last login',
    dataIndex: 'last_login',
    ellipsis: true,
    responsive: ['xl'],
    render: value => (value ? frontDate(value) : ''),
    sortDirections: ['descend'],
    sorter: (a, b) => a.last_login?.localeCompare(b.last_login || '0') || 0,
  },
  {
    title: 'Created',
    dataIndex: 'created_at',
    ellipsis: true,
    responsive: ['xl'],
    render: value => (value ? frontDate(value) : ''),
    sortDirections: ['descend'],
    sorter: (a, b) => a.last_login?.localeCompare(b.last_login || '0') || 0,
  },
  {
    title: 'Bots',
    dataIndex: 'subscriptions',
    ellipsis: true,
    responsive: ['md'],
    className: styles.botsCell,
    render: (value: IUserSubscription[], { id }: IUser) => {
      const names = (value || [])
        .filter(({ id: botId }) => !!params.bots[botId])
        .map(({ id: botId }) => [botId, params.bots[botId]]);
      names.sort();
      return (
        <>
          {names.map(([botId, name]) => (
            <p key={`user-${id}-bot-${botId}`}>{name}</p>
          ))}
        </>
      );
    },
    filters: bots.list
      .map(({ id, name }) => ({ text: name, value: id }))
      .sort((a, b) => a.text.localeCompare(b.text)),
    onFilter: (value, { subscriptions }) =>
      subscriptions?.some(({ id }) => id === value) || false,
  },
  {
    title: 'Agreement',
    dataIndex: 'agreement_accepted',
    width: 100,
    align: 'center',
    responsive: ['md'],
    render: (value?: string) =>
      !value ? (
        <Tag bordered={false} color="orange">
          pending
        </Tag>
      ) : (
        <Tag bordered={false} color="green">
          accepted
        </Tag>
      ),
    filters: [
      { text: 'Pending', value: false },
      { text: 'Active', value: true },
    ],
    onFilter: (value, { agreement_accepted }) => !!agreement_accepted === value,
  },
  {
    title: 'Status',
    dataIndex: 'account_status',
    width: 100,
    align: 'center',
    responsive: ['sm'],
    render: (value: TUserStatus) =>
      value === 'invited' ? (
        <Tag bordered={false} color="orange">
          invited
        </Tag>
      ) : value === 'inactive' ? (
        <Tag bordered={false} color="red">
          inactive
        </Tag>
      ) : (
        <Tag bordered={false} color="green">
          active
        </Tag>
      ),
    filters: [
      { text: 'Invited', value: 'invited' },
      { text: 'Inactive', value: 'inactive' },
      { text: 'Active', value: 'active' },
    ],
    onFilter: (value, { account_status }) => account_status === value,
  },
  {
    title: 'Role',
    width: 100,
    align: 'center',
    responsive: params.isAdmin ? ['sm'] : [],
    render: ({ admin, moderator }: IUser) =>
      admin ? (
        <Tag bordered={false} color="red">
          admin
        </Tag>
      ) : moderator ? (
        <Tag bordered={false} color="orange">
          moderator
        </Tag>
      ) : (
        ''
      ),
    filters: [
      { text: 'Moderator', value: 'moderator' },
      { text: 'Admin', value: 'admin' },
      { text: 'User', value: 'user' },
    ],
    onFilter: (value, { admin, moderator }) =>
      (value === 'moderator' && moderator && !admin) ||
      (value === 'admin' && admin) ||
      (value === 'user' && !moderator && !admin),
  },
  {
    title: () => (
      <div className={styles.create}>
        <Button
          size="middle"
          disabled={!params.inviteEnabled}
          onClick={params.onInvite}
        >
          Invite
        </Button>
        {params.isAdmin && (
          <Button size="middle" onClick={params.onCreate}>
            <PlusOutlined />
          </Button>
        )}
      </div>
    ),
    width: 160,
    render: ({ id, account_status }: IUser) => (
      <div className={styles.actions}>
        <Button
          size="small"
          disabled={params.statusing.includes(id)}
          onClick={() => params.onOpen(id)}
        >
          Open
        </Button>
        {id !== params.profileId && (
          <Button
            size="small"
            loading={params.statusing.includes(id)}
            disabled={account_status === 'invited'}
            onClick={() => params.onChangeStatus(id)}
          >
            {account_status === 'inactive' ? 'Enable' : 'Disable'}
          </Button>
        )}
      </div>
    ),
  },
];

const Users: React.FC = () => {
  const { inited, list, flags } = users;
  const flagIds = flags.map(({ user_id }) => user_id);
  const { sm } = useBreakpoint();

  const [loading, setLoading] = useState<boolean>(false);
  const init = async () => {
    try {
      setLoading(true);
      await loadUsers();
      await loadFlags();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    !inited && init();
  }, [inited]);

  const [openedId, setOpenedId] = useState<string | null>(null);
  const user = list.find(({ id }) => id === openedId);

  const handleClose = () => setOpenedId(null);

  const [statusing, setStatusing] = useState<string[]>([]);
  const handleChangeStatus = async (userId: string) => {
    const currentStatus: TUserStatus =
      users.list.find(({ id }) => id === userId)?.account_status || 'active';
    if (currentStatus === 'invited') return;
    try {
      setStatusing(ids => [...ids, userId]);
      await changeUserStatus(
        userId,
        currentStatus === 'inactive' ? 'active' : 'inactive'
      );
    } finally {
      setStatusing(ids => ids.filter(item => item !== userId));
    }
  };

  const [search, setSearch] = useState<string>('');
  const handleSearchInput = ({ currentTarget }: FormEvent<HTMLInputElement>) =>
    setSearch(currentTarget.value);
  const handleSearchKeyPress = ({
    nativeEvent,
  }: KeyboardEvent<HTMLInputElement>) =>
    nativeEvent.key === 'Escape' && setSearch('');

  const [flagged, setFlagged] = useState<boolean>(false);
  const handleFlaggedClisk = () => setFlagged(val => !val);

  const preparedUsers = [...list]
    .filter(
      ({ id, email, full_name }) =>
        (!search.trim().length ||
          email?.toLowerCase().includes(search.trim().toLowerCase()) ||
          full_name?.toLowerCase().includes(search.trim().toLowerCase()) ||
          id === search.trim()) &&
        (!flagged || flagIds.includes(id))
    )
    .sort((a, b) => a.email.localeCompare(b.email));

  const creationRef = useRef<TCreationRef>(null);
  const invitation = useInvitation();

  const isAdmin = !!profile.data?.admin;
  const isModerator = !!profile.data?.moderator;

  const profileBots = isAdmin
    ? bots.list
    : profile.data?.user_bots
    ? bots.list.filter(({ id }) => profile.data?.user_bots?.includes(id))
    : [];

  const botsIdName = useMemo(
    () => Object.fromEntries(bots.list.map(({ id, name }) => [id, name])),
    [bots.list]
  );

  const password = usePassword();

  return (
    <div className={styles.root}>
      <Table
        rowKey="id"
        loading={loading}
        columns={getColumns({
          profileId: profile.data?.id || '',
          inviteEnabled: !!profileBots.length,
          isAdmin,
          bots: botsIdName,
          onCreate: creationRef.current?.open,
          onInvite: invitation.open,
          onOpen: setOpenedId,
          onChangeStatus: handleChangeStatus,
          statusing,
          flags: flagIds,
        })}
        dataSource={preparedUsers}
        pagination={false}
        size="small"
        title={() => (
          <>
            <Input
              className={styles.search}
              placeholder="Search"
              value={search}
              allowClear
              onChange={handleSearchInput}
              onKeyDown={handleSearchKeyPress}
            />
            <Button
              type={flagged ? 'primary' : 'default'}
              onClick={handleFlaggedClisk}
            >
              Flagged
            </Button>
          </>
        )}
        sticky={{ offsetHeader: 86 }}
      />
      <Drawer
        className={styles.drawer}
        title={user?.email}
        width={1000}
        onClose={handleClose}
        open={!!openedId}
        extra={
          <Space.Compact>
            <Button onClick={() => user?.id && password.open?.(user?.id)}>
              Password
            </Button>
            <Button icon={!sm && <CloseOutlined />} onClick={handleClose}>
              {sm && 'Close'}
            </Button>
          </Space.Compact>
        }
      >
        {profile.data && user && (
          <UserForm
            profile={profile.data}
            user={user}
            bots={bots.list}
            flags={flags}
          />
        )}
      </Drawer>
      {isAdmin && <Creation ref={creationRef} onSuccess={setOpenedId} />}
      <Invitation bots={profileBots} />
    </div>
  );
};

export default observer(Users);
