import { notification } from 'antd';

import { TError } from 'api';

export const SUCCESS = {
  CREATE: 'Successfully created',
  UPDATE: 'Successfully saved',
  DELETE: 'Successfully deleted',
} as const;

export const alertInfo = (titleOrMessage: string, message?: string) =>
  notification.info({ message: titleOrMessage, description: message });

export const alertSuccess = (titleOrMessage: string, message?: string) =>
  notification.success({ message: titleOrMessage, description: message });

export const alertWarning = (titleOrMessage: string, message?: string) =>
  notification.warning({ message: titleOrMessage, description: message });

export const alertError = (titleOrMessage?: string, message?: string) =>
  notification.error({
    message: titleOrMessage || 'Something went wrong',
    description: message,
  });

export const processError = (error: TError) => {
  console.error(error);
  alertError(error?.cause?.message);
};
