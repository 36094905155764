import React, { ComponentProps, ReactNode, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import cn from 'classnames';

import styles from './styles.module.scss';

type TReactMarkdownProps = ComponentProps<typeof ReactMarkdown>;

export type TMarkdownButton = {
  key: string;
  onClick: (key?: string, content?: ReactNode) => void;
};

export type TMarkdownProps = {
  children: TReactMarkdownProps['children'];
  buttons?: TMarkdownButton[];
  className?: string;
};

const Markdown: React.FC<TMarkdownProps> = ({
  children,
  buttons,
  className,
}) => {
  const components = useMemo<TReactMarkdownProps['components']>(
    () => ({
      a: ({ href, children }) => {
        const button = buttons?.find(({ key }) => key === href);
        if (button) {
          return (
            <a onClick={() => button.onClick?.(href, children)}>{children}</a>
          );
        }
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },
    }),
    [buttons]
  );

  return (
    <ReactMarkdown
      components={components}
      className={cn(styles.root, className)}
    >
      {children}
    </ReactMarkdown>
  );
};

export default Markdown;
