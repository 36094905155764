import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Descriptions, Empty, Flex, Modal, Spin } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';

import { frontDate } from 'utils/format';
import { fetchUserProfile, generateUserProfile } from 'api/users';
import { IUserInfo } from 'store/users';

import TooltipButton from 'components/tooltipButton';

export type TProfileRef = {
  open: (chatId: string) => void;
};

const UPDATE_DELAY_SECONDS = 60 * 10;

const Profile = forwardRef<TProfileRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IUserInfo>({});
  const [updated, setUpdated] = useState<string>('');
  const [generating, setGenerating] = useState<boolean>(false);

  const chatIdRef = useRef<string>('');

  const loadData = async (chatId: string) => {
    chatIdRef.current = chatId;
    try {
      setLoading(true);
      const result = await fetchUserProfile(chatId);
      if (result?.data) {
        setData(result.data);
        setUpdated(result.updated || '0');
      }
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: (chatId: string) => {
      setData({});
      setUpdated('0');
      setOpened(true);
      loadData(chatId);
    },
  }));

  const handleClose = () => setOpened(false);

  const handleRegenerate = async () => {
    try {
      setGenerating(true);
      const result = await generateUserProfile(chatIdRef.current);
      if (result?.data) {
        setData(result.data);
        setUpdated(result.updated || '0');
      }
    } finally {
      setGenerating(false);
    }
  };

  const infoList = useMemo(() => {
    const { name, age, gender, ...restInfo } = data;
    const result: DescriptionsItemType[] = [];
    const addToList = (field: string, value?: string | string[]) =>
      value?.length &&
      result.push({
        key: field,
        label: field[0].toUpperCase() + field.substring(1).replaceAll('_', ' '),
        children:
          typeof value === 'string' ? (
            value
          ) : (
            <div>
              {value.map((line, index) => (
                <p key={`descriptions-${field}-${index.toString()}`}>{line}</p>
              ))}
            </div>
          ),
      });
    addToList('Name', name);
    addToList('Age', age);
    addToList('Gender', gender);
    Object.entries(restInfo).forEach(([field, value]) =>
      addToList(field, value)
    );
    return result;
  }, [data]);

  const updatedDate = new Date(frontDate(updated));
  const regenerateDisabled =
    loading ||
    (new Date().getTime() - updatedDate.getTime()) / 1000 <
      UPDATE_DELAY_SECONDS;

  return (
    <Modal
      title="Profile"
      cancelText="Close"
      open={opened}
      onCancel={handleClose}
      footer={(_, extra) => (
        <>
          <extra.CancelBtn />
          <TooltipButton
            title="Regenerate profile"
            tooltip={`Generated: ${frontDate(updated)}`}
            disabled={regenerateDisabled}
            loading={generating}
            type="primary"
            onClick={handleRegenerate}
          />
        </>
      )}
    >
      {loading ? (
        <Flex justify="center" align="center">
          <Spin />
        </Flex>
      ) : !infoList.length ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Descriptions size="small" column={1} items={infoList} />
      )}
    </Modal>
  );
});

export default Profile;
