/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';

import { signIn } from 'api/profile';
import { alertError } from 'utils/alert';
import { loadProfile, setAuthorized, updateToken } from 'store/profile';

import styles from './styles.module.scss';

interface IFormData {
  email: string;
  password: string;
}

const Auth: React.FC = () => {
  const [sending, setSending] = useState<boolean>(false);
  const handleSubmit = async (data: IFormData) => {
    let { email, password } = data;
    email = email.trim();
    password = password.trim();
    if (!email || !password) return;
    try {
      setSending(true);
      const { token, profile } = await signIn(email, password) || {};
      if (!token) {
        alertError('Invalid credentials');
      } else if (!profile?.admin && !profile?.moderator) {
        alertError('Access is denied');
      } else {
        updateToken(token);
        await loadProfile();
        await setAuthorized(true);
      }
    } finally {
      setSending(false);
    }
  };

  return (
    <Form<IFormData>
      className={styles.root}
      disabled={sending}
      onFinish={handleSubmit}
    >
      <Form.Item name="email" required>
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" required>
        <Input placeholder="Password" type="password" />
      </Form.Item>
      <div className={styles.buttons}>
        <Button htmlType="reset">Reset</Button>
        <Button type="primary" htmlType="submit" loading={sending}>
          Sign In
        </Button>
      </div>
    </Form>
  );
};

export default Auth;
