const texts = (name: string, company?: string) => ({
  prescreen: `
Beta Services Agreement ("Agreement") is made between Dynanetix, LLC ("Dynanetix"), and ${
    company || name || 'You'
  } ("Beta Tester"), collectively referred to as the "Parties." 

1. **Usage Terms**

By accessing and using the Beta Services (PrescreeAI.com, admin.prescreenai.com), you ${name} (as a person and on behalf of the ${
    company || name || 'Your Company'
  } agree to comply with the terms set forth by Dynanetix's [Terms of Use](terms) and [Privacy Policy](policy), both of which are hereby incorporated by reference. For the purposes of this agreement, the Beta Services are considered part of the "Services" as defined in the ToU and Privacy Policy. In cases where this Beta Agreement conflicts with the ToU, the provisions of this Beta Agreement will prevail regarding the use of the Beta Services.

2. **Recognition of Beta Service Limitations**

You recognize that:

   - (a) Dynanetix has not commercially released the Beta Services; 

   - (b) the Beta Services may be unstable, not in their final version, or not fully operational; 

   - (c) the Beta Services might contain errors, flaws in design, or other issues; 

   - (d) achieving full functionality of the Beta Services might be unfeasible; 

   - (e) utilizing the Beta Services could lead to unexpected outcomes, data corruption, or loss, along with other unforeseen damage; 

   - (f) Dynanetix is under no obligation to launch a commercially viable version of the Beta Services or continue their development. 

   You accept all risks related to the use of the Beta Services as outlined in this agreement. 

3. **Third-Party Obligations**

If you are bound by third-party agreements, such as those with telecommunication providers or data service purchases, you are solely responsible for adhering to those obligations. Dynanetix makes no representations that your use of the Beta Services complies with the requirements of any service provider or applicable laws and regulations. Dynanetix is not liable for any non-compliance with such external obligations. 

4. **License**

   4.1. **Grant of License**
Subject to the terms of this agreement, Dynanetix grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Beta Services solely for testing and evaluation purposes, and not for general production use or any commercial activities. 

   4.2. **Usage Restrictions**
You acknowledge that the Beta Services contain valuable trade secrets of Dynanetix and its licensors. To protect these secrets and other interests, you agree not to reverse engineer, decompile, disassemble, modify, distribute, sell, sublicense, transfer, or copy the Beta Services. 

5. **Feedback**
You agree to actively test the Beta Services and collaborate with Dynanetix in their evaluation. You are expected to provide feedback, comments, and suggestions for enhancements ("Feedback") as reasonably requested. You hereby grant Dynanetix a non-exclusive, worldwide, perpetual, irrevocable, royalty-free license, with the right to sublicense and transfer, to use and exploit the Feedback in any manner. 

6. **Ownership**
Dynanetix retains sole ownership of all rights, title, and interest in the Beta Services, including all associated intellectual property rights. You obtain no rights or licenses in the Beta Services or any confidential information provided by Dynanetix, except as explicitly stated in this agreement. 

7. **Confidentiality**

   7.1. **Definition**
"Confidential Information" includes all information related to the Beta Services and any other materials disclosed to you by Dynanetix that are marked as confidential or should reasonably be considered confidential given the nature of the information and the circumstances of disclosure. 

   7.2. **Obligations**
You commit to maintaining the confidentiality of all such information, not disclosing it to third Parties, and not using it for any purpose outside of this Beta Agreement. 

   7.3. **Return of Materials**
Upon termination of this agreement, or upon Dynanetix's request, you must promptly return or destroy all materials containing Confidential Information. 

   7.4. **Breach Consequences**
Any breach of this Beta Agreement could cause significant harm to Dynanetix, for which monetary damages might be insufficient. Accordingly, in addition to other remedies, Dynanetix may seek equitable relief, such as an injunction, to prevent any further breaches. 

8. **Termination**
Dynanetix reserves the right to modify, terminate, or restrict access to the Beta Services or this agreement at any time, at its sole discretion, without notice or liability. You may discontinue use of the Beta Services at any time. the following sections of this Beta Agreement will survive: 2, 3, 4.2, 5, 6, 7, 9, 10 and 11 will survive termination. 

9. **Warranty Disclaimers**
YOU ACKNOWLEDGE THAT THE BETA SERVICES ARE PROVIDED ON AN "AS IS" 

BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. DYNANETIX DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. DYNANETIX DOES NOT WARRANT THAT THE BETA SERVICES WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. 

10. **Limitation of Liability**
DYNANETIX WILL NOT BE LIABLE FOR ANY DAMAGES SUFFERED AS A RESULT OF USING, MODIFYING, CONTRIBUTING, COPYING, DISTRIBUTING, OR DOWNLOADING THE MATERIALS. IN NO EVENT SHALL DYNANETIX BE LIABLE FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF DYNANETIX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE PARTIES HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS BETA AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. TO THE EXTENT PERMITTED BY APPLICABLE LAW, DYNANETIX'S'S LIABILITY HEREUNDER IS LIMITED TO $50.00.  

11. **Miscellaneous**
This agreement is governed by the laws of the State of NY. The courts of New York City, NY, will have exclusive jurisdiction over any disputes. This document constitutes the entire agreement between the Parties regarding the Beta Services. If any part of this agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the Parties, and the remaining portions shall remain in full force and effect. 

12. No-Conflict and Non-Competition Clause 

By entering into this Beta Agreement, you undertake not to engage in any activities that directly compete with the primary business interests of Dynanetix for a period of two years following the termination of this agreement. Specifically, you are prohibited from: 

Being employed by, consulting for, or otherwise serving any entity that competes directly with the business operations, technologies, or services provided by Dynanetix. 

Assisting, developing, or advising on any products, services, or technologies that are in direct competition with those offered by Dynanetix during the term of your engagement with the Beta Services. 

Utilizing any proprietary or confidential information obtained during your tenure with Dynanetix to benefit any competing entities or to facilitate the development of competing products or services. 

13. Handling of Third-Party Personal Data 

During the course of using the Beta Services, the Beta Tester may provide Dynanetix with personal data from third parties, including but not limited to names, addresses, email addresses, resumes, and other personally identifiable information ("Third-Party Personal Data"). The Beta Tester warrants and represents that it has obtained all necessary consents and permissions from relevant third parties to collect, use, and share this data with Dynanetix. Furthermore, the Beta Tester agrees to comply with all applicable laws and regulations related to the protection of personal data, including but not limited to data privacy and data protection regulations. 

The Beta Tester acknowledges its responsibility to ensure that the Third-Party Personal Data is handled securely and with respect for the privacy of the individuals concerned. The Beta Tester agrees to indemnify and hold Dynanetix harmless from any claims, losses, liabilities, damages, fines, penalties, costs, and expenses, including reasonable attorneys' fees, arising from or relating to the Beta Tester's breach of this representation and warranty. 

14. End-User Notification and Responsibility

The Beta Tester agrees to notify any end-users referred by them to the Beta Services that they are participating in a Beta Program. This notification must clearly communicate that: 

* The Beta Services are in a beta phase and may have functionality or reliability limitations compared to commercially released software. 

* The Beta Services may contain bugs, errors, or other issues that could potentially impact performance and functionality. 

* The services are provided solely for testing and evaluation purposes and are not intended for use in production environments or situations where failure could result in business interruption or data loss. 

* The Beta Tester must ensure that all referred end-users understand these conditions and explicitly agree to participate under these terms prior to accessing the Beta Services. 

Furthermore, the Beta Tester is solely responsible for any issues that arise as a result of the referred end-users' use of the Beta Services. This includes responsibility for: 

* Providing personal data of referred end-users 

* Interruptions or failures that impact the usability of the Beta Services. 

* Any other adverse effects on the end-users' data or business operations due to their use of the Beta Services. 

The Beta Tester agrees to indemnify, defend, and hold harmless Dynanetix from any claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, associated with the referred end-users' use of the Beta Services. Dynanetix assumes no liability for any direct, indirect, incidental, special, or consequential damages incurred by the end-users due to their participation in the Beta Program.
        `,
  sifu: `
Beta Services Agreement ("Agreement") is made between Dynanetix, LLC ("Dynanetix"), and ${
    company || name || 'You'
  } ("Beta Tester"), collectively referred to as the "Parties." 

1. **Usage Terms**

By accessing and using the Beta Services (Sifu.one, admin.sifu.one), you ${name} (as a person and on behalf of the ${
    company || name || 'Your Company'
  } agree to comply with the terms set forth by Dynanetix's [Terms of Use](terms) and [Privacy Policy](policy), both of which are hereby incorporated by reference. For the purposes of this agreement, the Beta Services are considered part of the "Services" as defined in the ToU and Privacy Policy. In cases where this Beta Agreement conflicts with the ToU, the provisions of this Beta Agreement will prevail regarding the use of the Beta Services.

2. **Recognition of Beta Service Limitations**

You recognize that:

   - (a) Dynanetix has not commercially released the Beta Services; 

   - (b) the Beta Services may be unstable, not in their final version, or not fully operational; 

   - (c) the Beta Services might contain errors, flaws in design, or other issues; 

   - (d) achieving full functionality of the Beta Services might be unfeasible; 

   - (e) utilizing the Beta Services could lead to unexpected outcomes, data corruption, or loss, along with other unforeseen damage; 

   - (f) Dynanetix is under no obligation to launch a commercially viable version of the Beta Services or continue their development. 

   You accept all risks related to the use of the Beta Services as outlined in this agreement. 

3. **Third-Party Obligations**

If you are bound by third-party agreements, such as those with telecommunication providers or data service purchases, you are solely responsible for adhering to those obligations. Dynanetix makes no representations that your use of the Beta Services complies with the requirements of any service provider or applicable laws and regulations. Dynanetix is not liable for any non-compliance with such external obligations. 

4. **License**

   4.1. **Grant of License**
Subject to the terms of this agreement, Dynanetix grants you a limited, non-exclusive, non-transferable, revocable license to access and use the Beta Services solely for testing and evaluation purposes, and not for general production use or any commercial activities. 

   4.2. **Usage Restrictions**
You acknowledge that the Beta Services contain valuable trade secrets of Dynanetix and its licensors. To protect these secrets and other interests, you agree not to reverse engineer, decompile, disassemble, modify, distribute, sell, sublicense, transfer, or copy the Beta Services. 

5. **Feedback**
You agree to actively test the Beta Services and collaborate with Dynanetix in their evaluation. You are expected to provide feedback, comments, and suggestions for enhancements ("Feedback") as reasonably requested. You hereby grant Dynanetix a non-exclusive, worldwide, perpetual, irrevocable, royalty-free license, with the right to sublicense and transfer, to use and exploit the Feedback in any manner. 

6. **Ownership**
Dynanetix retains sole ownership of all rights, title, and interest in the Beta Services, including all associated intellectual property rights. You obtain no rights or licenses in the Beta Services or any confidential information provided by Dynanetix, except as explicitly stated in this agreement. 

7. **Confidentiality**

   7.1. **Definition**
"Confidential Information" includes all information related to the Beta Services and any other materials disclosed to you by Dynanetix that are marked as confidential or should reasonably be considered confidential given the nature of the information and the circumstances of disclosure. 

   7.2. **Obligations**
You commit to maintaining the confidentiality of all such information, not disclosing it to third Parties, and not using it for any purpose outside of this Beta Agreement. 

   7.3. **Return of Materials**
Upon termination of this agreement, or upon Dynanetix's request, you must promptly return or destroy all materials containing Confidential Information. 

   7.4. **Breach Consequences**
Any breach of this Beta Agreement could cause significant harm to Dynanetix, for which monetary damages might be insufficient. Accordingly, in addition to other remedies, Dynanetix may seek equitable relief, such as an injunction, to prevent any further breaches. 

8. **Termination**
Dynanetix reserves the right to modify, terminate, or restrict access to the Beta Services or this agreement at any time, at its sole discretion, without notice or liability. You may discontinue use of the Beta Services at any time. the following sections of this Beta Agreement will survive: 2, 3, 4.2, 5, 6, 7, 9, 10 and 11 will survive termination. 

9. **Warranty Disclaimers**
YOU ACKNOWLEDGE THAT THE BETA SERVICES ARE PROVIDED ON AN "AS IS" 

BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. DYNANETIX DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. DYNANETIX DOES NOT WARRANT THAT THE BETA SERVICES WILL MEET YOUR REQUIREMENTS, BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE. 

10. **Limitation of Liability**
DYNANETIX WILL NOT BE LIABLE FOR ANY DAMAGES SUFFERED AS A RESULT OF USING, MODIFYING, CONTRIBUTING, COPYING, DISTRIBUTING, OR DOWNLOADING THE MATERIALS. IN NO EVENT SHALL DYNANETIX BE LIABLE FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES. THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF DYNANETIX HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE PARTIES HAVE AGREED THAT THESE LIMITATIONS WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THIS BETA AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. TO THE EXTENT PERMITTED BY APPLICABLE LAW, DYNANETIX'S'S LIABILITY HEREUNDER IS LIMITED TO $50.00.  

11. **Miscellaneous**
This agreement is governed by the laws of the State of NY. The courts of New York City, NY, will have exclusive jurisdiction over any disputes. This document constitutes the entire agreement between the Parties regarding the Beta Services. If any part of this agreement is held invalid or unenforceable, that portion shall be construed in a manner consistent with applicable law to reflect, as nearly as possible, the original intentions of the Parties, and the remaining portions shall remain in full force and effect. 

12. No-Conflict and Non-Competition Clause 

By entering into this Beta Agreement, you undertake not to engage in any activities that directly compete with the primary business interests of Dynanetix for a period of two years following the termination of this agreement. Specifically, you are prohibited from: 

Being employed by, consulting for, or otherwise serving any entity that competes directly with the business operations, technologies, or services provided by Dynanetix. 

Assisting, developing, or advising on any products, services, or technologies that are in direct competition with those offered by Dynanetix during the term of your engagement with the Beta Services. 

Utilizing any proprietary or confidential information obtained during your tenure with Dynanetix to benefit any competing entities or to facilitate the development of competing products or services. 

13. Handling of Third-Party Personal Data 

During the course of using the Beta Services, the Beta Tester may provide Dynanetix with personal data from third parties, including but not limited to names, addresses, email addresses, resumes, and other personally identifiable information ("Third-Party Personal Data"). The Beta Tester warrants and represents that it has obtained all necessary consents and permissions from relevant third parties to collect, use, and share this data with Dynanetix. Furthermore, the Beta Tester agrees to comply with all applicable laws and regulations related to the protection of personal data, including but not limited to data privacy and data protection regulations. 

The Beta Tester acknowledges its responsibility to ensure that the Third-Party Personal Data is handled securely and with respect for the privacy of the individuals concerned. The Beta Tester agrees to indemnify and hold Dynanetix harmless from any claims, losses, liabilities, damages, fines, penalties, costs, and expenses, including reasonable attorneys' fees, arising from or relating to the Beta Tester's breach of this representation and warranty. 

14. End-User Notification and Responsibility

The Beta Tester agrees to notify any end-users referred by them to the Beta Services that they are participating in a Beta Program. This notification must clearly communicate that: 

* The Beta Services are in a beta phase and may have functionality or reliability limitations compared to commercially released software. 

* The Beta Services may contain bugs, errors, or other issues that could potentially impact performance and functionality. 

* The services are provided solely for testing and evaluation purposes and are not intended for use in production environments or situations where failure could result in business interruption or data loss. 

* The Beta Tester must ensure that all referred end-users understand these conditions and explicitly agree to participate under these terms prior to accessing the Beta Services. 

Furthermore, the Beta Tester is solely responsible for any issues that arise as a result of the referred end-users' use of the Beta Services. This includes responsibility for: 

* Providing personal data of referred end-users 

* Interruptions or failures that impact the usability of the Beta Services. 

* Any other adverse effects on the end-users' data or business operations due to their use of the Beta Services. 

The Beta Tester agrees to indemnify, defend, and hold harmless Dynanetix from any claims, liabilities, damages, costs, and expenses, including reasonable attorneys' fees, associated with the referred end-users' use of the Beta Services. Dynanetix assumes no liability for any direct, indirect, incidental, special, or consequential damages incurred by the end-users due to their participation in the Beta Program.
        `,
});

const relations: Record<string, keyof ReturnType<typeof texts>> = {
  localhost: 'prescreen',
  'ai-chat-admin-fe.dev.processica.com': 'prescreen',
  prescreenai: 'prescreen',
  sifu: 'sifu',
};

const getData = (host: string, name: string, company?: string) =>
  texts(name, company)[
    Object.entries(relations).find(([key]) =>
      host.toLowerCase().includes(key.toLowerCase())
    )?.[1] as keyof ReturnType<typeof texts>
  ];

export default getData;
