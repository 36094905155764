import { get, post, remove, TError, uri } from 'api';
import { processError } from 'utils/alert';
import { IHREvaluation, IHRInfo, IHRPosition } from 'store/users';

export const fetchHRJobPositions = async (): Promise<IHRPosition[]> => {
  try {
    const { data } = await get<IHRPosition[]>(['admin_hr', 'job_positions']);
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};

export const sendHRJobPosition = async (
  name: string,
  job_description: string,
  success_criteria: string | null,
  questions_to_ask: string | null
): Promise<IHRPosition | null> => {
  try {
    const body: Record<string, string> = {
      name,
      job_description,
    };
    success_criteria && (body.success_criteria = success_criteria);
    questions_to_ask && (body.questions_to_ask = questions_to_ask);
    const { data } = await post<IHRPosition>(
      ['admin_hr', 'job_positions'],
      body
    );
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const fetchHRInfo = async (
  user_id: string,
  bot_id: string
): Promise<IHRInfo | null> => {
  try {
    const { data } = await get<IHRInfo>(['admin', 'service_data'], {
      user_id,
      bot_id,
    });
    return data || null;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const sendHrInfo = async (info: IHRInfo): Promise<IHRInfo | null> => {
  try {
    const { data } = await post<IHRInfo>(['admin', 'service_data'], {
      ...info,
    });
    return data;
  } catch (error) {
    processError(error as TError);
    return null;
  }
};

export const resetHRInfo = async (
  user_id: string,
  bot_id: string
): Promise<boolean> => {
  try {
    await remove(uri(['admin', 'service_data'], { user_id, bot_id }));
    return true;
  } catch (error) {
    processError(error as TError);
    return false;
  }
};

export const fetchHREvaluation = async (
  chat_id: string
): Promise<IHREvaluation[]> => {
  try {
    const { data } = await get<IHREvaluation[]>(['admin_hr', 'evaluate'], {
      chat_id,
    });
    return data;
  } catch (error) {
    processError(error as TError);
    return [];
  }
};
