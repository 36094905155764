import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';

import Markdown from 'components/markdown';

import getData from './data';

import styles from './styles.module.scss';

const policy: { open?: () => void } = {};

export const usePolicy = () => policy;

const Policy: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false);

  useEffect(() => {
    policy.open = () => setOpened(true);
    return () => (policy.open = undefined);
  }, []);

  const handleClose = () => setOpened(false);

  return (
    <Modal
      title="Privacy and cookies policy"
      cancelText="Close"
      open={opened}
      width={800}
      onCancel={handleClose}
      closable={false}
      maskClosable={false}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      <Markdown className={styles.markdown}>
        {getData(location.hostname)}
      </Markdown>
    </Modal>
  );
};

export default Policy;
