import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { Form, Input, Modal, Select, Space } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';

import { alertSuccess } from 'utils/alert';
import { IUser } from 'store/users';
import { duplicateBot, IBot } from 'store/bots';

export type TBotsRef = { open: () => void };
export type TBotsProps = {
  user: IUser;
  bots: IBot[];
};

interface IFormData {
  bot: string;
  name: string;
  host: string;
}

const Bots = forwardRef<TBotsRef, TBotsProps>(({ user, bots }, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [botId, setBotId] = useState<string>('');
  const [filled, setFilled] = useState<boolean>(false);
  const [form] = Form.useForm<IFormData>();

  useImperativeHandle(ref, () => ({
    open: () => {
      setFilled(false);
      form.resetFields();
      setOpened(true);
    },
  }));

  const handleClose = () => setOpened(false);

  const sourceBot = bots.find(({ id }) => id === botId);
  const sourceBotHost = (sourceBot?.host || '').replace(
    /(^https?:\/+|\/+$)/g,
    ''
  );

  const sourceBotsOptions: DefaultOptionType[] = useMemo(
    () =>
      bots
        .filter(({ source }) => source)
        .map(({ id, name }) => ({
          value: id,
          label: name,
        })),
    [bots]
  );

  const handleChange = () => {
    const values = form.getFieldsValue();
    setFilled(
      !!botId &&
        values.name?.trim().length > 3 &&
        values.host?.trim().length > 3
    );
  };

  useEffect(handleChange, [botId]);

  const handleSubmit = async ({ name, host }: IFormData) => {
    try {
      setLoading(true);
      const result = await duplicateBot(
        user.id,
        botId,
        name,
        host + (sourceBotHost ? `.${sourceBotHost}` : '')
      );
      if (result) {
        alertSuccess('Selected Bot has been copied');
        handleClose();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Bot settings"
      okText="Add"
      cancelText="Cancel"
      open={opened}
      width={800}
      confirmLoading={loading}
      okButtonProps={{ disabled: !filled }}
      onOk={form.submit}
      onCancel={handleClose}
      maskClosable={false}
    >
      <Form<IFormData>
        form={form}
        autoComplete="off"
        labelCol={{ span: 7 }}
        onChange={handleChange}
        onFinish={handleSubmit}
      >
        <Form.Item label="Select source bot" name="bot" required>
          <Select options={sourceBotsOptions} onChange={setBotId} />
        </Form.Item>
        <Form.Item label="New bot name" name="name" required>
          <Input />
        </Form.Item>{' '}
        <Form.Item label="New bot host" name="host" required>
          <Space.Compact block>
            <Input />
            {!!sourceBotHost && <Input value={`.${sourceBotHost}`} disabled />}
          </Space.Compact>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default Bots;
