import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Modal, Space } from 'antd';

import { resetPassword } from 'store/users';
import { generatePassword } from 'utils/data';

type TCloseCallback = () => void;

const password: { open?: (userId: string, onClose?: TCloseCallback) => void } =
  {};

export const usePassword = () => password;

const Password: React.FC = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const callbackRef = useRef<TCloseCallback>();

  useEffect(() => {
    password.open = (userId, onClose) => {
      setUserId(userId);
      setValue('');
      setOpened(true);
      callbackRef.current = onClose;
    };
    return () => (password.open = undefined);
  }, []);

  const handleGenerate = () => setValue(generatePassword());

  const handleClose = () => {
    setOpened(false);
    callbackRef.current?.();
  };

  const [saving, setSaving] = useState<boolean>(false);

  const isValid = value.length >= 8;

  const handleSubmit = async () => {
    if (!isValid) return;
    try {
      setSaving(true);
      const result = await resetPassword(userId, value);
      result && handleClose();
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal
      title="Enter new password"
      okText="Save"
      cancelText="Close"
      open={opened}
      confirmLoading={saving}
      okButtonProps={{ disabled: !isValid }}
      onOk={handleSubmit}
      onCancel={handleClose}
    >
      <Space.Compact block>
        <Input
          placeholder="New password"
          aria-errormessage="Minimum 8 characters"
          value={value}
          showCount
          maxLength={30}
          status={!isValid ? 'warning' : undefined}
          disabled={saving}
          onChange={({ currentTarget }) => setValue(currentTarget.value)}
        />
        <Button disabled={saving} onClick={handleGenerate}>
          Generate
        </Button>
      </Space.Compact>
    </Modal>
  );
};

export default Password;
