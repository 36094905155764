import React, { JSX, useState } from 'react';
import cn from 'classnames';
import { Button, Typography, Upload as AntdUpload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { CheckCircleTwoTone, UploadOutlined } from '@ant-design/icons';

import { getHeaders, TUrl, uri } from 'api';
import { alertError } from 'utils/alert';

import styles from './styles.module.scss';

type TUploadProps<Response> = {
  title: string | JSX.Element;
  accept?: string;
  url: TUrl;
  field?: string;
  filled?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  buttonClassName?: string;
  onUpload?: (data: Response) => void;
  onError?: (error?: any) => void;
};

function Upload<Response = any>({
  title,
  accept,
  url,
  field,
  filled,
  disabled,
  required,
  className,
  buttonClassName,
  onUpload,
  onError,
}: TUploadProps<Response>): JSX.Element {
  const [uploading, setUploading] = useState<boolean>(false);

  const handleChange = async ({ file }: UploadChangeParam<UploadFile<any>>) => {
    if (file.status === 'done' && file.response.error) {
      file.status = 'error';
    }
    if (file.status === 'done') {
      await onUpload?.(file.response.data);
    }
    if (file.status === 'error') {
      await onError?.(file.response.error);
      alertError('Something went wrong while uploading');
    }
    setUploading(file.status === 'uploading');
  };

  return (
    <AntdUpload
      className={cn(styles.root, className)}
      accept={accept}
      showUploadList={false}
      action={uri(url)}
      data={file => ({ [field || 'file']: file })}
      headers={getHeaders()}
      disabled={disabled || uploading}
      onChange={handleChange}
    >
      <Button
        block
        icon={filled ? <CheckCircleTwoTone /> : <UploadOutlined />}
        loading={uploading}
        disabled={disabled || uploading}
        className={buttonClassName}
      >
        {title}
        {required && <Typography.Text type="danger">*</Typography.Text>}
      </Button>
    </AntdUpload>
  );
}

export default Upload;
