import React, { FC, forwardRef, useId, useImperativeHandle, useState } from 'react';
import { Modal, Typography } from 'antd';

export type TTextRef = { open: (title: string, text: string) => void };

const Text = forwardRef<TTextRef>((_, ref) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');

  const id = useId();

  useImperativeHandle(ref, () => ({
    open: (_title, _text) => {
      setTitle(_title);
      setText(_text);
      setOpened(true);
    },
  }));

  const handleClose = () => setOpened(false);

  const paragraphs = text.trim().split('\n');

  return (
    <Modal
      title={title}
      cancelText="Close"
      open={opened}
      width={800}
      onCancel={handleClose}
      footer={(_, extra) => <extra.CancelBtn />}
    >
      {paragraphs.map((paragraph, index) => (
        <Typography.Paragraph key={`text-${id}-line-${index}`}>
          {paragraph}
        </Typography.Paragraph>
      ))}
    </Modal>
  );
});

export default Text;
