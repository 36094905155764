import copy from 'copy-to-clipboard';

export const validateEmail = (email: string) =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const generatePassword = () =>
  Math.random()
    .toString(36)
    .substring(2)
    .split('')
    .map(char => (Math.random() > 0.5 ? char.toUpperCase() : char))
    .join('');

export const copyToClipboard = (text: string) =>
  new Promise<boolean>(async resolve => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const result = await (isSafari
      ? copy(text)
      : navigator.clipboard
          .writeText(text)
          .then(() => true)
          .catch(() => false));
    resolve(result);
  });
