import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Flex, Input, Row, Space, Typography } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

import { fetchHRInfo, sendHrInfo } from 'api/hr';
import { IUser, IHRInfo } from 'store/users';
import { IBot } from 'store/bots';
import Upload from 'components/upload';

import { TFileToText } from '../../invitation';
import Text, { TTextRef } from '../../text';

import styles from '../../../styles.module.scss';

export type THRProps = {
  userId: IUser['id'];
  botId: IBot['id'];
  onChange?: (data: IHRInfo | null) => void;
};

const HR: React.FC<THRProps> = ({ userId, botId, onChange }) => {
  const { sm } = useBreakpoint();

  const textRef = useRef<TTextRef>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [info, setInfo] = useState<IHRInfo | null>(null);
  const [infoUpdating, setInfoUpdating] = useState<boolean>(false);

  useEffect(() => {
    onChange?.(info);
  }, [info]);

  const [texts, setTexts] = useState<
    Required<{ criterias: string; questions: string }>
  >({ criterias: '', questions: '' });
  const updateTexts = (values: { criterias?: string; questions?: string }) =>
    setTexts(({ criterias, questions }) => ({
      criterias: values.criterias ?? criterias,
      questions: values.questions ?? questions,
    }));

  useEffect(() => {
    setLoading(true);
    fetchHRInfo(userId, botId)
      .then(setInfo)
      .finally(() => setLoading(false));
  }, [userId, botId]);

  useEffect(() => {
    updateTexts({
      criterias: info?.success_criteria,
      questions: info?.questions_to_ask,
    });
  }, [info?.success_criteria, info?.questions_to_ask]);

  const handleTextsReset = () => {
    updateTexts({
      criterias: info?.success_criteria,
      questions: info?.questions_to_ask,
    });
  };

  const handleTextsUpdate = async () => {
    try {
      setInfoUpdating(true);
      const info = await sendHrInfo({
        user_id: userId,
        bot_id: botId,
        success_criteria: texts.criterias,
        questions_to_ask: texts.questions,
      });
      setInfo(info);
    } finally {
      setInfoUpdating(false);
    }
  };

  const isTextsButtonDisabled =
    texts.criterias === info?.success_criteria &&
    texts.questions === info?.questions_to_ask;

  const handleFilesUpdate = async (field: keyof IHRInfo, data: string) => {
    try {
      setInfoUpdating(true);
      const info = await sendHrInfo({
        user_id: userId,
        bot_id: botId,
        [field]: data,
      });
      setInfo(info);
    } finally {
      setInfoUpdating(false);
    }
  };

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24} sm={12}>
          <Space.Compact block>
            <Upload<TFileToText>
              className={styles.upload}
              title="Upload Job Requirements"
              accept="application/pdf"
              url="file_to_text"
              filled={!!info?.job_description}
              disabled={loading}
              onUpload={({ content }) =>
                handleFilesUpdate('job_description', content)
              }
            />
            <Button
              disabled={!info?.job_description}
              onClick={() =>
                textRef.current?.open(
                  'Job Requirements',
                  info?.job_description || ''
                )
              }
            >
              Show
            </Button>
          </Space.Compact>
        </Col>
        <Col span={24} sm={12}>
          <Space.Compact block>
            <Upload<TFileToText>
              className={styles.upload}
              title="Upload CV"
              accept="application/pdf"
              url="file_to_text"
              filled={!!info?.cv}
              disabled={loading}
              onUpload={({ content }) => handleFilesUpdate('cv', content)}
            />
            <Button
              disabled={!info?.cv}
              onClick={() => textRef.current?.open('CV', info?.cv || '')}
            >
              Show
            </Button>
          </Space.Compact>
        </Col>
        <Col span={24} sm={10}>
          <Typography.Text>Special criterias</Typography.Text>
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            disabled={infoUpdating}
            value={texts?.criterias}
            onChange={({ currentTarget }) =>
              updateTexts({ criterias: currentTarget.value })
            }
          />
        </Col>
        <Col span={24} sm={10}>
          <Typography.Text>Additional questions</Typography.Text>
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            disabled={infoUpdating}
            value={texts?.questions}
            onChange={({ currentTarget }) =>
              updateTexts({ questions: currentTarget.value })
            }
          />
        </Col>
        <Col span={24} sm={4}>
          <Flex vertical className={styles.hrTextsButtons}>
            {sm && <Typography.Text>{' '}</Typography.Text>}
            <Space.Compact direction={sm ? 'vertical' : 'horizontal'}>
              <Button
                block
                disabled={isTextsButtonDisabled}
                loading={infoUpdating}
                onClick={handleTextsUpdate}
              >
                Update
              </Button>
              <Button
                block
                disabled={isTextsButtonDisabled || infoUpdating}
                onClick={handleTextsReset}
              >
                Reset
              </Button>
            </Space.Compact>
          </Flex>
        </Col>
      </Row>
      <Text ref={textRef} />
    </>
  );
};

export default HR;
