import React, { useMemo, useState } from 'react';
import { List } from 'antd';

import { IUser } from 'store/users';
import { IBot } from 'store/bots';

import styles from '../../../styles.module.scss';

export type TOwnedProps = {
  user: IUser;
  bots: IBot[];
};

const Owned: React.FC<TOwnedProps> = ({ user, bots }) => {
  const userBots = bots.filter(({ id }) => user.user_bots?.includes(id));

  return (
    <>
      <List
        size="small"
        className={styles.ownedBots}
        dataSource={userBots}
        rowKey={({ id }) => `user-${user.id}-bot-${id}`}
        renderItem={({ name }) => <List.Item>{name}</List.Item>}
      />
    </>
  );
};

export default Owned;
